import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import type {
  Hero4Block,
  ButtonComponent,
  MediaComponent,
} from '@sceneio/schemas/lib/types'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { mergeDeepRight } from '@sceneio/tools'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { randomString } from '@sceneio/tools'
import { DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES } from '../../../components/Button/constants/globalButtonCssVariables'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from '../SplitHero/form/additionalFormFieldRenderers'

const MEDIA_DEFAULTS = mediaDefaults.config

export const buttonSampleFactory = (data = {}): ButtonComponent => ({
  id: randomString(8),
  children: textToLexical('Button', 'array-item'),
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
  ...data,
})

export const hero4ComponentMeta: BlockComponentMeta<Hero4Block> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      minHeight: {
        data: {
          '--min-height-default': '100vh',
        },
      },
      background: {
        responsiveConfig: {
          default: {
            image: {
              src: 'https://assets.scene.io/global/placeholders/media-1200x900.png',
            },
          },
        },
        fit: 'cover',
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
      margin: {
        data: {
          '--margin-default-bottom': '2rem',
          '--margin-default-top': '2rem',
          '--margin-default-left': '3.75rem',
          '--margin-default-right': '3.75rem',
          '--margin-xs-left': '2rem',
          '--margin-xs-right': '2rem',
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      responsiveConfig: {
        default: {
          maxWidth: '45%',
          rowGap: '1.5rem',
        },
        xs: {
          maxWidth: '100%',
        },
      },

      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      padding: {
        data: {
          '--padding-default-bottom': '3rem',
          '--padding-default-top': '3rem',
          '--padding-default-left': '1rem',
          '--padding-default-right': '1rem',
        },
      },
    },
    type: 'blockContainer',
  },
  heading: {
    path: 'components.heading',
    name: 'Heading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.heading',
      },
    ],
    defaultConfig: {
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h1'],
      children: textToLexical('Medium length hero headline goes here', 'h1'),
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      as: 'h1',
      show: true,
    },
    type: 'text',
  },
  subheading: {
    path: 'components.subheading',
    name: 'Subheading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.subheading',
      },
    ],
    defaultConfig: {
      children: textToLexical('Subheading for a block'),
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      as: 'p',
      show: false,
    },
    type: 'text',
  },
  text: {
    path: 'components.text',
    name: 'Text',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.text',
        editorOptions: {
          include: ['table', 'list'],
        },
      },
    ],
    defaultConfig: {
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
      ),
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      as: 'p',
      show: true,
    },
    type: 'text',
  },
  author: {
    path: 'components.author',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.author',
      },
    ],
    defaultConfig: {
      children: textToLexical('Author Name'),
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      as: 'p',
      show: false,
    },
    name: 'Author',
    type: 'text',
  },
  logo: {
    path: 'components.logo',
    name: 'Logo',
    defaultConfig: {
      responsiveConfig: {
        default: {
          src: 'https://assets.scene.io/global/placeholders/logo-ipsum-vertical.svg',
          width: 170,
        },
      },
      show: false,
    },
    type: 'image',
  },
  background: {
    path: 'components.background',
    name: 'Media layer',
    components: {
      credits: {
        path: 'components.credits',
        inlineEditors: [
          {
            editorType: 'inlineText',
            editorPath: 'components.background.components.credits',
          },
        ],
        disableEditing: true,
        name: 'Credits',
        type: 'text',
      },
    },
    defaultConfig: {
      ...(mergeDeepRight(MEDIA_DEFAULTS, {
        components: {
          ...MEDIA_DEFAULTS.components,
          video: {
            isBackgroundVideo: true,
          },
        },
        show: false,
      }) as MediaComponent),
    },
    type: 'media',
  },
  buttons: {
    path: 'components.buttons',
    components: {
      items: {
        isArrayComponent: true,
        path: 'items',
        name: 'Button',
        type: 'button',
        disableToggleShow: true,
        sampleFactory: buttonSampleFactory,
      },
    },
    inlineEditors: [
      {
        editorType: 'buttonEditor',
        editorPath: 'components.buttons.items',
        editorOptions: {
          isItemArray: true,
          sampleFactory: buttonSampleFactory,
        },
      },
    ],
    defaultConfig: {
      items: [
        buttonSampleFactory({
          children: textToLexical('CTA Button', 'array-item'),
          ...DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES,
        }),
        buttonSampleFactory(),
      ] as ButtonComponent[],
      gap: {
        data: {
          [`--row-gap-default`]: '1rem',
          [`--column-gap-default`]: '0.75rem',
        },
      },
      flow: {
        data: {
          '--flow-default': 'row',
          '--flow-xs': 'column',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'flex-start',
        },
      },
      padding: {
        data: {
          '--padding-default-top': '1rem',
        },
      },
      show: true,
    },
    name: 'Buttons',
    type: 'container',
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
        },
      },
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
}

export const hero4BlockMeta: BlockMeta<Hero4Block> = {
  defaultConfig: {},
  configEditor: {},
}

export const hero4DefaultConfig = blockDefaultConfigFactory<Hero4Block>({
  componentMeta: hero4ComponentMeta,
  blockMeta: hero4BlockMeta,
  version: 1,
})
