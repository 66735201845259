import { createContext, createRef, ReactNode, RefObject } from 'react'

type CallbackType = (data: {
  name?: string
  data?: string | Record<string, any>[]
  isItemArray?: boolean
}) => void

export type WysiwygContextType = {
  onSubmit: CallbackType
  resolvePathFromId?: (id: string) => string
  anchorRef: RefObject<HTMLElement>
}

type WysiwygProviderType = WysiwygContextType & {
  children: ReactNode
}

export const WysiwygContext = createContext<WysiwygContextType>({
  resolvePathFromId: () => '',
  onSubmit: () => {},
  anchorRef: createRef<HTMLElement>(),
})

export function WysiwygContextProvider({
  children,
  onSubmit,
  anchorRef,
  resolvePathFromId,
}: WysiwygProviderType) {
  return (
    <WysiwygContext.Provider value={{ onSubmit, anchorRef, resolvePathFromId }}>
      {children}
    </WysiwygContext.Provider>
  )
}
