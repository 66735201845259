import { BlockComponent, SplitHeroBlock } from '@sceneio/schemas/lib/types'
import { randomString } from '@sceneio/tools'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import {
  blockLayoutAdditionalFormFieldRenderer,
  buttonContainerLayoutAdditionalFormFieldRenderer,
} from './form/additionalFormFieldRenderers'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'
import {
  DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES,
  DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES,
} from '../../../components/Button/constants/globalButtonCssVariables'
import { textToLexical } from '@sceneio/wysiwyg-utils'

const MEDIA_DEFAULTS = mediaDefaults.config
const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

export const buttonSampleFactory = (data = {}) => ({
  id: randomString(8),
  children: textToLexical('Button', 'array-item'),
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
  ...data,
})

export const splitHeroComponentMeta: BlockComponentMeta<SplitHeroBlock> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      swapOrder: false,
      columnRatio: 6,
    } as BlockComponent & {
      swapOrder?: boolean
      columnRatio: number
    },
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: blockLayoutAdditionalFormFieldRenderer,
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      responsiveConfig: {
        default: {
          maxWidth: 'screen',
          rowGap: '1.5rem',
          columnGap: '2rem',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      padding: {
        data: {
          ...BLOCK_CONTAINER_DEFAULTS.padding?.data,
          '--padding-xs-left': '0rem',
          '--padding-xs-right': '0rem',
        },
      },
    },
    type: 'blockContainer',
  },
  mediaContainer: {
    path: 'components.mediaContainer',
    name: 'Media container',
    type: 'container',
    defaultConfig: {
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'flex-start',
        },
      },
    },
    disableToggleShow: true,
    configEditor: {
      formProps: {
        name: 'group',
        registerValues: containerComponentFormRegisterValuesFactory('group'),
      },
    },
  },
  media: {
    path: 'components.media',
    name: 'Media',
    components: {
      credits: {
        path: 'components.credits',
        inlineEditors: [
          {
            editorType: 'inlineText',
            editorPath: 'components.media.components.credits',
          },
        ],
        disableEditing: true,
        name: 'Credits',
        type: 'text',
      },
    },
    defaultConfig: MEDIA_DEFAULTS,
    type: 'media',
  },
  contentContainer: {
    path: 'components.contentContainer',
    name: 'Content container',
    type: 'container',
    defaultConfig: {
      padding: {
        data: {
          '--padding-xs-top': '2rem',
          '--padding-xs-bottom': '2rem',
          '--padding-xs-left': '2rem',
          '--padding-xs-right': '2rem',
        },
      },
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
      gap: {
        data: {
          [`--row-gap-default`]: '1.5rem',
        },
      },
    },
    disableToggleShow: true,
    configEditor: {
      formProps: {
        name: 'group',
        registerValues: containerComponentFormRegisterValuesFactory('group'),
      },
    },
  },
  logo: {
    path: 'components.logo',
    name: 'Logo',
    defaultConfig: {
      responsiveConfig: {
        default: {
          src: 'https://assets.scene.io/global/placeholders/logo-ipsum-horizontal.svg',
          width: 170,
        },
      },
      show: false,
    },
    configEditor: {
      formProps: {
        excludedSettings: ['height'],
      },
    },
    type: 'image',
  },
  subheading: {
    path: 'components.subheading',
    name: 'Subheading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.subheading',
      },
    ],
    defaultConfig: {
      children: textToLexical('Subheading for a block'),
      as: 'p',
      show: false,
    },
    type: 'text',
  },
  heading: {
    path: 'components.heading',
    name: 'Heading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.heading',
      },
    ],
    defaultConfig: {
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h1'],
      children: textToLexical('Medium length hero {headline} goes here', 'h1'),
      as: 'h1',
      show: true,
    },
    type: 'text',
  },
  text: {
    path: 'components.text',
    name: 'Text',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.text',
        editorOptions: {
          include: ['table', 'list'],
        },
      },
    ],
    defaultConfig: {
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
      ),
      as: 'p',
      show: true,
    },
    type: 'text',
  },
  author: {
    path: 'components.author',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.author',
      },
    ],
    defaultConfig: {
      children: textToLexical('Author Name'),
      as: 'p',
      show: false,
    },
    name: 'Author',
    type: 'text',
  },
  buttons: {
    path: 'components.buttons',
    components: {
      items: {
        isArrayComponent: true,
        path: 'items',
        name: 'Button',
        type: 'button',
        disableToggleShow: true,
        sampleFactory: () =>
          buttonSampleFactory({ ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES }),
      },
    },
    inlineEditors: [
      {
        editorType: 'buttonEditor',
        editorPath: 'components.buttons.items',
        editorOptions: {
          isItemArray: true,
          sampleFactory: () =>
            buttonSampleFactory({ ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES }),
        },
      },
    ],
    defaultConfig: {
      items: [
        buttonSampleFactory({
          children: textToLexical('CTA Button', 'array-item'),
          ...DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES,
        }),
        buttonSampleFactory({ ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES }),
      ],
      show: true,
      padding: {
        data: {
          '--padding-default-top': '1rem',
        },
      },
      gap: {
        data: {
          [`--row-gap-default`]: '1rem',
          [`--column-gap-default`]: '1rem',
        },
      },
      flow: {
        data: {
          '--flow-default': 'row',
          '--flow-xs': 'column',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'flex-start',
        },
      },
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
    },
    name: 'Buttons',
    type: 'container',
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
        },
      },
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
}

export const splitHeroBlockMeta: BlockMeta<SplitHeroBlock> = {}

export const splitHeroDefaultConfig = blockDefaultConfigFactory<SplitHeroBlock>(
  {
    componentMeta: splitHeroComponentMeta,
    blockMeta: splitHeroBlockMeta,
    version: 1,
  },
)
