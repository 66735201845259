import { InferType, array, bool, mixed, object, string } from 'yup'
import { reactNodeSchema } from '../shared/reactNodeSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { horizontalAlignmentFlexStyleSchema } from '../styles/horizontalAlignmentFlexStyleSchema'
import { verticalAlignmentStyleSchema } from '../styles/verticalAlignmentStyleSchema'
import { styleSchema } from '../shared/styleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'
import { columnsStyleSchema } from '../styles/columnsStyleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'

export const gridComponentSchema = object({
  cssType: string().oneOf(['grid', 'flex']),
  items: array().of(mixed<any>()),
  columns: columnsStyleSchema.optional(),
  columnClassName: string(),
  className: string(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  horizontalAlignment: horizontalAlignmentFlexStyleSchema.optional(),
  verticalAlignment: verticalAlignmentStyleSchema.optional(),
  gap: gapStyleSchema.optional(),
  fillRow: bool(),
  show: bool(),
  style: styleSchema,
  editor: componentEditorSchema,
}).noUnknown()

export type GridComponent = InferType<typeof gridComponentSchema>

export type GridComponentStyles = Pick<
  GridComponent,
  | 'margin'
  | 'padding'
  | 'boxShadow'
  | 'horizontalAlignment'
  | 'verticalAlignment'
  | 'border'
  | 'columns'
  | 'gap'
>
