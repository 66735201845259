import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import type {
  ButtonComponent,
  CTABanner2Block,
  MediaComponent,
} from '@sceneio/schemas/lib/types'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { mergeDeepRight } from '@sceneio/tools'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { randomString } from '@sceneio/tools'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from '../../Hero/SplitHero/form/additionalFormFieldRenderers'

const MEDIA_DEFAULTS = mediaDefaults.config

export const buttonSampleFactory = (data = {}): ButtonComponent => ({
  id: randomString(8),
  children: textToLexical('Button', 'array-item'),
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
  ...data,
})

export const ctaBanner2ComponentMeta: BlockComponentMeta<CTABanner2Block> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      background: {
        responsiveConfig: {
          default: {
            color: '#F4F4F4',
          },
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
      margin: {
        data: {
          '--margin-default-bottom': '4rem',
          '--margin-default-top': '4rem',
          '--margin-default-left': '4rem',
          '--margin-default-right': '4rem',
          '--margin-xs-left': '2rem',
          '--margin-xs-right': '2rem',
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      responsiveConfig: {
        default: {
          maxWidth: '60%',
          rowGap: '1.5rem',
        },
        xs: {
          maxWidth: '100%',
        },
      },

      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      padding: {
        data: {
          '--padding-default-bottom': '4rem',
          '--padding-default-top': '4rem',
          '--padding-default-left': '1rem',
          '--padding-default-right': '1rem',
        },
      },
    },
    type: 'blockContainer',
  },
  heading: {
    path: 'components.heading',
    name: 'Heading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.heading',
      },
    ],
    defaultConfig: {
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
      children: textToLexical('Medium length section heading goes here', 'h2'),
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      as: 'h2',
      show: true,
    },
    type: 'text',
  },
  subheading: {
    path: 'components.subheading',
    name: 'Tagline',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.subheading',
      },
    ],
    defaultConfig: {
      children: textToLexical('Tagline'),
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      font: {
        data: {
          default: {
            '--font-default-weight-default': 600,
          },
        },
      },
      as: 'p',
    },
    type: 'text',
  },
  text: {
    path: 'components.text',
    name: 'Text',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.text',
        editorOptions: {
          include: ['table', 'list'],
        },
      },
    ],
    defaultConfig: {
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
      ),
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      font: {
        data: {
          default: {
            '--font-default-size-default': '18px',
          },
        },
      },
      as: 'p',
      show: true,
    },
    type: 'text',
  },
  author: {
    path: 'components.author',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.author',
      },
    ],
    defaultConfig: {
      children: textToLexical('Author Name'),
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      as: 'p',
      show: false,
    },
    name: 'Author',
    type: 'text',
  },
  logo: {
    path: 'components.logo',
    name: 'Logo',
    defaultConfig: {
      responsiveConfig: {
        default: {
          src: 'https://assets.scene.io/global/placeholders/logo-ipsum-vertical.svg',
          width: 170,
        },
      },
      show: false,
    },
    type: 'image',
  },
  background: {
    path: 'components.background',
    name: 'Media layer',
    components: {
      credits: {
        path: 'components.credits',
        inlineEditors: [
          {
            editorType: 'inlineText',
            editorPath: 'components.background.components.credits',
          },
        ],
        disableEditing: true,
        name: 'Credits',
        type: 'text',
      },
    },
    defaultConfig: {
      ...(mergeDeepRight(MEDIA_DEFAULTS, {
        components: {
          ...MEDIA_DEFAULTS.components,
          video: {
            isBackgroundVideo: true,
          },
        },
        show: false,
      }) as MediaComponent),
    },
    type: 'media',
  },
  buttons: {
    path: 'components.buttons',
    components: {
      items: {
        isArrayComponent: true,
        path: 'items',
        name: 'Button',
        type: 'button',
        disableToggleShow: true,
        sampleFactory: buttonSampleFactory,
      },
    },
    inlineEditors: [
      {
        editorType: 'buttonEditor',
        editorPath: 'components.buttons.items',
        editorOptions: {
          isItemArray: true,
          sampleFactory: buttonSampleFactory,
        },
      },
    ],
    defaultConfig: {
      items: [
        buttonSampleFactory({
          children: textToLexical('Button', 'array-item'),
        }),
      ] as ButtonComponent[],
      gap: {
        data: {
          [`--row-gap-default`]: '1rem',
          [`--column-gap-default`]: '0.75rem',
        },
      },
      flow: {
        data: {
          '--flow-default': 'row',
          '--flow-xs': 'column',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'flex-start',
        },
      },
      padding: {
        data: {
          '--padding-default-top': '1rem',
        },
      },
      show: true,
    },
    name: 'Buttons',
    type: 'container',
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
        },
      },
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
}

export const ctaBanner2BlockMeta: BlockMeta<CTABanner2Block> = {
  defaultConfig: {},
  configEditor: {},
}

export const ctaBanner2DefaultConfig =
  blockDefaultConfigFactory<CTABanner2Block>({
    componentMeta: ctaBanner2ComponentMeta,
    blockMeta: ctaBanner2BlockMeta,
    version: 1,
  })
