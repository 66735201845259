import { FontStyle, TextComponentStyles } from '@sceneio/schemas/lib/types'

export const DEFAULT_TEXT_CSS_VARIABLES: TextComponentStyles = {
  font: {
    data: {
      default: {
        '--font-default-size-default': '1rem',
        '--font-default-family-default': 'Inter, sans-serif',
        '--font-default-style-default': 'normal',
        '--font-default-weight-default': 'normal',
        '--font-default-line-height-default': 1.4,
        '--font-default-word-spacing-default': '0em',
        '--font-default-letter-spacing-default': '0em',
        '--font-default-text-transform-default': 'none',
        '--font-default-text-decoration-color-default': '#000',
        '--font-default-text-decoration-style-default': 'solid',
        '--font-default-text-decoration-line-default': 'none',
      },
    },
  },
  color: {
    data: {
      default: {
        '--color-default-default': '#000',
      },
    },
  },
  linkStyle: {
    data: {
      default: {
        '--link-default-color-default': 'inherit',
        '--link-default-weight-default': 'inherit',
        '--link-default-style-default': 'inherit',
        '--link-default-background-color-default': 'transparent',
        '--link-default-text-decoration-color-default': '#000',
        '--link-default-text-decoration-style-default': 'solid',
        '--link-default-text-decoration-line-default': 'none',
      },
      hover: {
        '--link-default-color-hover': 'inherit',
        '--link-default-weight-hover': 'inherit',
        '--link-default-style-hover': 'inherit',
        '--link-default-background-color-hover': 'transparent',
        '--link-default-text-decoration-color-hover': '#000',
        '--link-default-text-decoration-style-hover': 'solid',
        '--link-default-text-decoration-line-hover': 'underline',
      },
      active: {
        '--link-default-color-active': 'inherit',
        '--link-default-weight-active': 'inherit',
        '--link-default-style-active': 'inherit',
        '--link-default-background-color-active': 'transparent',
        '--link-default-text-decoration-color-active': '#000',
        '--link-default-text-decoration-style-active': 'solid',
        '--link-default-text-decoration-line-active': 'underline',
      },
    },
  },
  margin: {
    data: {
      '--margin-default-bottom': 0,
      '--margin-default-top': 0,
      '--margin-default-left': 0,
      '--margin-default-right': 0,
    },
  },
  padding: {
    data: {
      '--padding-default-bottom': 0,
      '--padding-default-top': 0,
      '--padding-default-left': 0,
      '--padding-default-right': 0,
    },
  },
}

export const DEFAULT_HEADINGS_CSS_VARIABLES: {
  [key in 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6']: {
    font: FontStyle
  }
} = {
  h1: {
    font: {
      data: {
        default: {
          '--font-default-size-default': '3rem',
          '--font-md-size-default': '2.75rem',
          '--font-xs-size-default': '2.5rem',
          '--font-default-weight-default': 'bold',
          '--font-default-line-height-default': 1.2,
        },
      },
    },
  },
  h2: {
    font: {
      data: {
        default: {
          '--font-default-size-default': '2.25rem',
          '--font-md-size-default': '2rem',
          '--font-xs-size-default': '1.75rem',
          '--font-default-weight-default': 'bold',
          '--font-default-line-height-default': 1.19,
        },
      },
    },
  },
  h3: {
    font: {
      data: {
        default: {
          '--font-default-size-default': '1.75rem',
          '--font-md-size-default': '1.5rem',
          '--font-xs-size-default': '1.25rem',
          '--font-default-weight-default': 'bold',
          '--font-default-line-height-default': 1.2,
        },
      },
    },
  },
  h4: {
    font: {
      data: {
        default: {
          '--font-default-size-default': '1.25rem',
          '--font-md-size-default': '1.15rem',
          '--font-xs-size-default': '1.15rem',
          '--font-default-weight-default': 'bold',
          '--font-default-line-height-default': 1.3,
        },
      },
    },
  },
  h5: {
    font: {
      data: {
        default: {
          '--font-default-size-default': '1.1rem',
          '--font-default-weight-default': 'bold',
        },
      },
    },
  },
  h6: {
    font: {
      data: {
        default: {
          '--font-default-size-default': '1rem',
          '--font-default-weight-default': 'bold',
        },
      },
    },
  },
}
