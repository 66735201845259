import type { SVGProps } from 'react'
const SvgSidesBlrt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#252523"
      d="M4.5 2a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zM4.5 13a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zM13.5 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M3 4.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0z"
    />
  </svg>
)
export default SvgSidesBlrt
