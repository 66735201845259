import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'

export const buttonComponentMeta: ComponentMeta<'button'> = {
  defaultConfig: {
    disabled: false,
    isAnimated: true,
    show: true,
    dataAttributes: {},
    link: {
      type: 'internal',
      href: '#',
      target: '_blank',
    },
  },
}

export const buttonDefaults = componentDefaultConfigFactory<'button'>({
  componentMeta: buttonComponentMeta,
})
