import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Text from '../../../components/Text/Text'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { featureWithFormDefaultConfig } from './featureWithFormMeta'
import type { FeatureWithFormBlock } from '@sceneio/schemas/lib/types'
import Media from '../../../components/Media/Media'
import Form from '../../../components/Form/Form'

function FeatureWithForm({
  id,
  components: { block, tagline, heading, text, form, bottomText, media },
  editor,
  preview,
}: FeatureWithFormBlock = featureWithFormDefaultConfig) {
  const { className, swapOrder, columnRatio, ...restBlockProps } = block || {}
  const featureWithFormClass = clsx('sc-feature-with-form', className)

  const contentColumns = columnRatio!
  const mediaColumns = 12 - contentColumns

  const mediaColumnClasses = clsx('sc-feature-with-form__media-column', {
    [`bui-col-md-${mediaColumns}`]: mediaColumns,
    'bui-order-md-last': swapOrder,
  })

  const contentColumnClasses = clsx(`sc-feature-with-form__content-column`, {
    [`bui-col-md-${contentColumns}`]: contentColumns,
    [`bui-col`]: contentColumns === 0,
  })

  return (
    <Block
      className={featureWithFormClass}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <div className="bui-row">
        <div className={mediaColumnClasses}>
          <Media
            {...media}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'media',
            }}
            components={{
              ...media.components,
              caption: {
                show: false,
              },
            }}
            className="sc-feature-with-form__media"
            cid={resolveComponentId(id, 'media')}
            preview={preview}
          />
        </div>
        <div className={contentColumnClasses}>
          <Text
            {...tagline}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'tagline',
            }}
            cid={resolveComponentId(id, 'tagline')}
          />
          <Text
            {...heading}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'heading',
            }}
            cid={resolveComponentId(id, 'heading')}
          />
          <Text
            {...text}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'text',
            }}
            cid={resolveComponentId(id, 'text')}
          />
          <Form
            {...form}
            editor={{ ...editor?.component, blockId: id, id: 'form' }}
            preview={preview}
            cid={resolveComponentId(id, 'form')}
          />
          <Text
            {...bottomText}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'bottomText',
            }}
            cid={resolveComponentId(id, 'bottomText')}
          />
        </div>
      </div>
    </Block>
  )
}
export const featureWithFormPlugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}

export default FeatureWithForm
