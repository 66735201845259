import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Text from '../../../components/Text/Text'
import { PluginsType } from '../../../scripts/externalPluginsTypes'
import {
  footerVariant1DefaultConfig,
  navLinkSampleFactory,
} from './footerVariant1Meta'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { Container } from '../../../components/Container/Container'
import Image from '../../../components/Image/Image'
import Divider from '../../../components/Divider/Divider'
import Button from '../../../components/Button/Button'
import {
  FooterVariant1Block,
  FooterVariant1ColumnConfig,
} from '@sceneio/schemas/lib/types'
import { ComponentEditor } from '@sceneio/schemas/lib/shared/componentEditorSchema'
import { editorItemsControlsFactory } from '../../../tools/editor/editor'

function FooterColumn({
  id,
  preview,
  blockId,
  editor,
  col,
  config,
}: {
  config: FooterVariant1ColumnConfig
  editor: FooterVariant1Block['editor']
  col: number
  blockId?: string
  preview?: boolean
  id: 'column1' | 'column2' | 'column3' | 'column4'
}) {
  const { logo, upperText, bottomText, menu, menuTitle } = config

  return (
    <Container
      className={clsx('sc-footer-column', {
        [`bui-col-md-${col}`]: col,
      })}
      flow={{ data: { '--flow-default': 'column' } }}
      name="group"
      editor={{
        ...editor?.component,
        blockId,
        id,
      }}
      {...config}
    >
      {logo?.show && (
        <a
          className="sc-footer__brand bui-d-flex"
          href={preview ? null : ('/' as any)}
        >
          <Image
            {...logo}
            className={clsx('sc-footer__logo', logo.className)}
            isAnimated={false}
            editor={{
              ...editor?.component,
              blockId: blockId,
              id: `${id}.logo`,
            }}
          />
        </a>
      )}
      <Text
        {...upperText}
        className={clsx('sc-footer-column__upper-text', upperText?.className)}
        editor={{
          ...editor?.component,
          blockId: blockId,
          id: `${id}.upperText`,
        }}
        isAnimated={false}
        cid={resolveComponentId(`${blockId}-${id}`, 'upper-text')}
      />
      <Text
        {...menuTitle}
        className={clsx('sc-footer-column__menu-title', menuTitle?.className)}
        editor={{
          ...editor?.component,
          blockId: blockId,
          id: `${id}.menuTitle`,
        }}
        isAnimated={false}
        cid={resolveComponentId(`${blockId}-${id}`, 'menu-title')}
      />
      <Container
        as="ul"
        {...menu}
        className={clsx(
          'navbar-nav sc-footer__column-menu-nav bui-align-items-center bui-align-items-md-start',
        )}
        name="componentArray"
        editor={{
          ...editor?.component,
          blockId: blockId,
          id: `${id}.menu`,
        }}
      >
        <NavList
          items={menu?.items}
          resolveEditor={(index) => ({
            ...editor?.component,
            blockId,
            id: `${id}.menu.items[${index}]`,
            itemsId: `${id}.menu.items`,
          })}
        />
      </Container>
      <Text
        {...bottomText}
        className={clsx('sc-footer-column__bottom-text', bottomText?.className)}
        editor={{
          ...editor?.component,
          blockId: blockId,
          id: `${id}.bottomText`,
        }}
        isAnimated={false}
        cid={resolveComponentId(`${blockId}-${id}`, 'bottom-text')}
      />
      {/* <Social
            presentation="regular"
            {...social}
            editor={{
              ...editor?.component,
              blockId: blockId,
              id: `${id}.social`,
            }}
            className="tm-footer__social sc-footer-column__social"
          /> */}
    </Container>
  )
}

function NavList({
  items = [],
  linkClassName,
  listItemClassName,
  resolveEditor,
}: {
  linkClassName?: string
  listItemClassName?: string
  items?: any[]
  resolveEditor: (index?: number) => ComponentEditor
}) {
  return (
    <>
      {items.map((item, index) => {
        const resolvedEditor = resolveEditor(index)
        return (
          <li key={item.id} className={clsx('nav-item', listItemClassName)}>
            <Button
              {...item}
              className={clsx(
                'nav-link sc-footer__sub-nav-link',
                linkClassName,
              )}
              editor={{
                ...resolvedEditor,
                wysiwyg: {
                  type: 'ITEMS',
                  itemsArr: items,
                  ...editorItemsControlsFactory({
                    itemsArr: items,
                    itemsId: resolvedEditor?.itemsId || '',
                    itemFactory: () => navLinkSampleFactory(),
                    index,
                  }),
                },
              }}
              isAnimated={false}
            />
          </li>
        )
      })}
    </>
  )
}

export function FooterVariant1({
  id,
  components: {
    block,
    column1,
    column2,
    column3,
    column4,
    copyright,
    secondaryMenu,
    social,
    divider,
  },
  preview,
  editor,
}: FooterVariant1Block = footerVariant1DefaultConfig) {
  const footerClassName = clsx('sc-footer sc-footer--v1 tm-footer', {
    'sc-header--block-view': preview,
  })

  const columnShowArray = [
    column1?.show,
    column2?.show,
    column3?.show,
    column4?.show,
  ]

  const showMainArea = columnShowArray.includes(true)

  const showSecondaryMenuArea = secondaryMenu?.show || social?.show

  const columnCount = columnShowArray.filter(Boolean).length

  const resolvedColumnCol = columnCount > 0 ? 12 / columnCount : 1

  return (
    <Block
      as="footer"
      id={id}
      className={footerClassName}
      editor={editor}
      {...block}
    >
      {showMainArea && (
        <div className={clsx('bui-row')}>
          <FooterColumn
            col={resolvedColumnCol}
            preview={preview}
            blockId={id}
            config={column1}
            editor={editor}
            id="column1"
          />
          <FooterColumn
            col={resolvedColumnCol}
            preview={preview}
            config={column2}
            blockId={id}
            editor={editor}
            id="column2"
          />
          <FooterColumn
            col={resolvedColumnCol}
            preview={preview}
            config={column3}
            blockId={id}
            editor={editor}
            id="column3"
          />
          <FooterColumn
            col={resolvedColumnCol}
            preview={preview}
            config={column4}
            blockId={id}
            editor={editor}
            id="column4"
          />
        </div>
      )}
      <Divider
        {...divider}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'divider',
        }}
      />
      {showSecondaryMenuArea && (
        <div className={clsx('bui-row')}>
          {secondaryMenu?.show && (
            <div
              className={clsx({
                'bui-col-md-8': social?.show,
                'col-12': !social?.show,
                'bui-order-md-1': secondaryMenu?.swapOrder,
              })}
            >
              <Container
                as="ul"
                className={clsx('navbar-nav bui-justify-content-start')}
                name="componentArray"
                {...secondaryMenu}
                editor={{
                  ...editor?.component,
                  blockId: id,
                  id: 'secondaryMenu',
                }}
              >
                <NavList
                  items={secondaryMenu?.items}
                  resolveEditor={(index) => ({
                    ...editor?.component,
                    blockId: id,
                    id: `secondaryMenu.items[${index}]`,
                    itemsId: 'secondaryMenu.items',
                  })}
                />
              </Container>
            </div>
          )}
          {/* {social?.show && (
                <div
                  className={clsx('bui-d-flex bui-justify-content-center', {
                    'bui-col-md-4': secondaryMenu?.show,
                    'col-12': !secondaryMenu?.show,
                  })}
                >
                  <Social
                    isAnimated={false}
                    presentation="regular"
                    {...social}
                    editor={{
                      ...editor?.component,
                      blockId: id,
                      id: 'social',
                    }}
                    className="sc-footer__social"
                  />
                </div>
              )} */}
        </div>
      )}
      {copyright?.show && (
        <div className={clsx('bui-row')}>
          <div className="bui-col-12">
            <Text
              {...copyright}
              className={clsx('sc-footer__copyright', copyright?.className)}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'copyright',
              }}
              isAnimated={false}
              cid={resolveComponentId(id, 'copyright')}
            />
          </div>
        </div>
      )}
    </Block>
  )
}

export const footerVariant1Plugins: PluginsType = {
  js: ['social'],
  css: [],
}
