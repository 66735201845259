import { InferType, bool, object, string } from 'yup'
import { ariaAttributesSchema } from '../shared/ariaAttributesSchema'
import { ariaRoleSchema } from '../shared/ariaRoleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { styleSchema } from '../shared/styleSchema'
import { backgroundColorStyleSchema } from '../styles/backgroundColorStyleSchema'
import { colorStyleSchema } from '../styles/colorStyleSchema'
import { fontStyleSchema } from '../styles/fontStyleSchema'
import { horizontalAlignmentStyleSchema } from '../styles/horizontalAlignmentStyleSchema'
import { linkStyleSchema } from '../styles/linkStyleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { textShadowStyleSchema } from '../styles/textShadowStyleSchema'
import { enableEditorSchema } from './common/enableEditorSchema'
import { linkSchema } from './common/linkSchema'

export const textComponentSchema = object({
  children: string(),
  className: string(),
  as: string().oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'span',
    'a',
    'div',
  ]),
  cid: string(),
  id: string(),
  link: linkSchema.optional(),
  horizontalAlignment: horizontalAlignmentStyleSchema.optional(),
  color: colorStyleSchema.optional(),
  dataAttributes: object().optional(),
  ariaAttributes: ariaAttributesSchema,
  style: styleSchema,
  font: fontStyleSchema.optional(),
  textShadow: textShadowStyleSchema.optional(),
  linkStyle: linkStyleSchema.optional(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  backgroundColor: backgroundColorStyleSchema.optional(),
  isAnimated: bool(),
  role: ariaRoleSchema,
  enableEditor: enableEditorSchema,
  editor: componentEditorSchema,
  shouldHyphenate: bool(),
  show: bool(),
}).noUnknown()

export type TextComponent = InferType<
  typeof textComponentSchema & {
    as?: keyof JSX.IntrinsicElements
  }
>

export type TextComponentStyles = Pick<
  TextComponent,
  | 'font'
  | 'linkStyle'
  | 'margin'
  | 'padding'
  | 'horizontalAlignment'
  | 'textShadow'
  | 'color'
  | 'backgroundColor'
>
