import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { createPortal } from 'react-dom'
import { ItemsToolbar } from './ItemsToolbar'
import React from 'react'

export type FloatingItemsToolbar = {
  onAdd: () => void
  onRemove: () => void
  disableAdd: boolean
  disableRemove: boolean
  containerRef: React.RefObject<HTMLElement>
  enableAI?: boolean
}

export default function FloatingItemsToolbarPlugin({
  anchorElem,
  onAdd,
  onRemove,
  disableAdd,
  disableRemove,
  containerRef,
}: FloatingItemsToolbar & {
  anchorElem: HTMLElement
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext()
  return createPortal(
    <ItemsToolbar
      onAdd={onAdd}
      onRemove={onRemove}
      disableAdd={disableAdd}
      disableRemove={disableRemove}
      editor={editor}
      containerRef={containerRef}
      enableAI={true}
    />,
    anchorElem,
  )
}
