import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { randomString } from '@sceneio/tools'
import type { FAQ2Block } from '@sceneio/schemas/lib/types'
import { accordionItemDefaults } from '../../../components/AccordionItem/accordionItemMeta'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'
import { accordionDefaults } from '../../../components/Accordion/accordionMeta'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from '../../Hero/SplitHero/form/additionalFormFieldRenderers'

const ACCORDION_DEFAULTS = accordionDefaults.config
const ACCORDION_ITEM_DEFAULTS = accordionItemDefaults.config
const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

const accordionItemSampleFactory = () => ({
  id: randomString(),
  ...ACCORDION_ITEM_DEFAULTS,
})

export const buttonSampleFactory = () => ({
  id: randomString(8),
  children: textToLexical('Contact us', 'array-item'),
  backgroundColor: {
    data: {
      default: {
        '--background-color-default-default': 'transparent',
      },
      hover: {
        '--background-color-default-hover': '#000',
      },
    },
  },
  border: {
    data: {
      default: {
        '--border-default-color-default': '#000',
        '--border-default-width-default': '1px',
        '--border-default-style-default': 'solid',
        '--border-default-top-style-default': 'solid',
        '--border-default-bottom-style-default': 'solid',
        '--border-default-left-style-default': 'solid',
        '--border-default-right-style-default': 'solid',
      },
    },
  },
  color: {
    data: {
      default: {
        '--color-default-default': '#000',
      },
      hover: {
        '--color-default-hover': '#fff',
      },
    },
  },
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
})

export const fAQ2ComponentMeta: BlockComponentMeta<FAQ2Block> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      responsiveConfig: {
        default: {
          maxWidth: 'screen',
          columnGap: '1.5rem',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      padding: BLOCK_CONTAINER_DEFAULTS.padding,
    },
    type: 'blockContainer',
  },
  topHeading: {
    path: 'components.topHeading',
    name: 'Top Heading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.topHeading',
      },
    ],
    defaultConfig: {
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
      children: textToLexical('FAQs', 'h2'),
      as: 'h2',
      margin: {
        data: {
          '--margin-default-bottom': '1.5rem',
        },
      },
    },
    type: 'text',
  },
  topText: {
    path: 'components.topText',
    name: 'Top Text',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.topText',
        editorOptions: {
          include: ['table', 'list'],
        },
      },
    ],
    defaultConfig: {
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
      ),
      as: 'p',
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
    type: 'text',
  },
  accordion1: {
    path: 'components.accordion1',
    name: 'Accordion 1',
    type: 'accordion',
    disableToggleShow: true,
    components: {
      items: {
        isArrayComponent: true,
        sampleFactory: accordionItemSampleFactory,
        path: 'items',
        name: 'Accordion Item',
        type: 'accordionItem',
        components: {
          headerContainer: {
            type: 'container',
            name: 'Header',
            path: 'components.headerContainer',
            configEditor: {
              formProps: {
                name: 'group',
                registerValues:
                  containerComponentFormRegisterValuesFactory('group'),
              },
            },
          },
          bodyContainer: {
            type: 'container',
            name: 'Body',
            path: 'components.bodyContainer',
            configEditor: {
              formProps: {
                name: 'group',
                registerValues:
                  containerComponentFormRegisterValuesFactory('group'),
              },
            },
          },
          heading: {
            type: 'text',
            name: 'Heading',
            path: 'components.heading',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.heading',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          text: {
            type: 'text',
            name: 'Text',
            path: 'components.text',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.text',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          icon: {
            type: 'button',
            name: 'Icon',
            path: 'components.icon',
          },
        },
      },
    },
    defaultConfig: {
      items: [
        accordionItemSampleFactory(),
        accordionItemSampleFactory(),
        accordionItemSampleFactory(),
        accordionItemSampleFactory(),
        accordionItemSampleFactory(),
      ],
      ...ACCORDION_DEFAULTS,
      margin: {
        data: {
          '--margin-default-top': '5rem',
          '--margin-default-bottom': '5rem',
          '--margin-xs-bottom': '0.5rem',
        },
      },
    },
    configEditor: {
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
  accordion2: {
    path: 'components.accordion2',
    name: 'Accordion 2',
    type: 'accordion',
    disableToggleShow: true,
    components: {
      items: {
        isArrayComponent: true,
        sampleFactory: accordionItemSampleFactory,
        path: 'items',
        name: 'Accordion Item',
        type: 'accordionItem',
        components: {
          headerContainer: {
            type: 'container',
            name: 'Header',
            path: 'components.headerContainer',
            configEditor: {
              formProps: {
                name: 'group',
                registerValues:
                  containerComponentFormRegisterValuesFactory('group'),
              },
            },
          },
          bodyContainer: {
            type: 'container',
            name: 'Body',
            path: 'components.bodyContainer',
            configEditor: {
              formProps: {
                name: 'group',
                registerValues:
                  containerComponentFormRegisterValuesFactory('group'),
              },
            },
          },
          heading: {
            type: 'text',
            name: 'Heading',
            path: 'components.heading',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.heading',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          text: {
            type: 'text',
            name: 'Text',
            path: 'components.text',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.text',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          icon: {
            type: 'button',
            name: 'Icon',
            path: 'components.icon',
          },
        },
      },
    },
    defaultConfig: {
      items: [
        accordionItemSampleFactory(),
        accordionItemSampleFactory(),
        accordionItemSampleFactory(),
        accordionItemSampleFactory(),
        accordionItemSampleFactory(),
      ],
      ...ACCORDION_DEFAULTS,
      margin: {
        data: {
          '--margin-default-top': '5rem',
          '--margin-default-bottom': '5rem',
          '--margin-xs-top': '0.5rem',
        },
      },
    },
    configEditor: {
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
  bottomHeading: {
    path: 'components.bottomHeading',
    name: 'Bottom Heading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.bottomHeading',
      },
    ],
    defaultConfig: {
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
      children: textToLexical('Still have a question?', 'h2'),
      as: 'h2',
      margin: {
        data: {
          '--margin-default-bottom': '1.5rem',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
    type: 'text',
  },
  bottomText: {
    path: 'components.bottomText',
    name: 'Bottom Text',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.bottomText',
        editorOptions: {
          include: ['table', 'list'],
        },
      },
    ],
    defaultConfig: {
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      ),
      as: 'p',
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      margin: {
        data: {
          '--margin-default-bottom': '1.5rem',
        },
      },
    },
    type: 'text',
  },
  buttons: {
    path: 'components.buttons',
    components: {
      items: {
        isArrayComponent: true,
        path: 'items',
        name: 'Button',
        type: 'button',
        disableToggleShow: true,
        sampleFactory: () => buttonSampleFactory(),
      },
    },
    inlineEditors: [
      {
        editorType: 'buttonEditor',
        editorPath: 'components.buttons.items',
        editorOptions: {
          isItemArray: true,
          sampleFactory: () => buttonSampleFactory(),
        },
      },
    ],
    defaultConfig: {
      items: [buttonSampleFactory()],
      show: true,
      gap: {
        data: {
          [`--row-gap-default`]: '1rem',
          [`--column-gap-default`]: '1rem',
        },
      },
      flow: {
        data: {
          '--flow-default': 'row',
          '--flow-xs': 'column',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
    },
    name: 'Buttons',
    type: 'container',
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
        },
      },
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
}

export const fAQ2BlockMeta: BlockMeta<FAQ2Block> = {}

export const fAQ2DefaultConfig = blockDefaultConfigFactory<FAQ2Block>({
  componentMeta: fAQ2ComponentMeta,
  blockMeta: fAQ2BlockMeta,
  version: 1,
})
