import { createContext, useCallback, useContext } from 'react'
import { search } from 'jmespath'
import { flatten, unflatten } from 'flat'
import { normalizeJMESPath, stringify } from '@sceneio/tools'
import { useFormContext as rootUseFormContext } from '@sceneio/form'
import { FormContextType as UseFormReturnType } from '@sceneio/form/lib/FormContext'
import {
  SnippetType,
  SnippetConfigType,
} from '../../../../../../snippets-tools/lib/snippetsTypes'
import type { FontsMap, FontType } from '@sceneio/config-fonts/lib/fonts'

export type SNIPPET_TYPE = SnippetConfigType

type ParentSnippetType = {
  id: string
  path: string
  hasUnpublishedChanges: boolean
}

export type FormBreakpoint = 'xs' | 'md' | 'lg'

export type FormContextType = {
  snippets?: SnippetType[]
  meta?: Record<string, any>
  rawPlaceholders?: Record<string, any>
  snippetCallbacks?: {
    onCreateSnippet: (arg0: any) => void
    onAssignSnippet: (arg0: any) => void
    onChangeSnippetName: (arg0: any) => void
    onDetachSnippet: (arg0: any) => void
    onDeleteSnippet: (arg0: any) => void
    onIssueSnippet: (arg0: any) => void
    onDiscardSnippet: (arg0: any) => void
  }
  contentCallbacks?: {
    onEditDefaultFrameClick: (arg0: { focusedMolecule?: string }) => void
    onUploadFontClick: () => void
  }
  customFonts?: FontsMap
  usedFonts?: FontType[]
  forceBreakpoint?: FormBreakpoint
  focusedMolecule?: string
  parentSnippet?: ParentSnippetType
  excludedSettings?: string[]
}

export const FormContext = createContext<FormContextType>({} as any)

const getMetaSubsetByName = ({
  name,
  data = {},
}: {
  name?: string
  data?: Record<string, any>
}): Record<string, any> => {
  const metaPath = `${normalizeJMESPath(name || '') || '@'}.snippetMeta`
  const meta = search(unflatten(data), metaPath) ?? {}
  return meta
}

const getDataSubsetByName = ({
  name,
  data = {},
}: {
  name?: string
  data?: Record<string, any>
}): Record<string, any> => {
  if (!name) {
    return data
  }

  return flatten({
    [name]: search(unflatten(data), normalizeJMESPath(name)) ?? undefined,
  })
}

const getSnippetsByType = ({
  type,
  snippets = [],
}: {
  type?: string
  snippets?: SnippetType[]
}) => {
  if (!type) {
    return snippets
  }
  return snippets.filter((snippet) => search(snippet, 'type') === type)
}

export const FormContextProvider = ({
  children,
  parentSnippet,
  ...rest
}: FormContextType & {
  children: JSX.Element | JSX.Element[]
}) => {
  const { parentSnippet: previousParentSnippet, ...restSnippetData } =
    useContext(FormContext)

  return (
    <FormContext.Provider
      value={
        {
          ...{ ...restSnippetData, ...rest },
          parentSnippet: parentSnippet?.id
            ? parentSnippet
            : previousParentSnippet,
        } as FormContextType
      }
    >
      {children}
    </FormContext.Provider>
  )
}

export type UseFormContextReturnType = UseFormReturnType & {
  snippets: SnippetType[]
  meta: Record<string, any>
  rawPlaceholders?: Record<string, any>
  onCreateSnippet: (arg0: any) => void
  onAssignSnippet: (arg0: any) => void
  onChangeSnippetName: (arg0: any) => void
  onDetachSnippet: (arg0: any) => void
  onDeleteSnippet: (arg0: any) => void
  onIssueSnippet: (arg0: any) => void
  onDiscardSnippet: (arg0: any) => void
  onEditDefaultFrameClick: (arg0: { focusedMolecule?: string }) => void
  onUploadFontClick: () => void
  customFonts?: FontsMap
  usedFonts?: FontType[]
  parentSnippet?: ParentSnippetType
  allowSaveSnippet: boolean
  forceBreakpoint?: FormBreakpoint
  focusedMolecule?: string
  excludedSettings: string[]
}

export const useFormContext = ({
  snippetsType,
  metaPath,
  name,
}: {
  snippetsType?: string
  metaPath?: string
  name?: string
} = {}): UseFormContextReturnType => {
  const formContext = useContext(FormContext)
  const parentSnippet = formContext.parentSnippet
  const { setValue, setValues, register, unregister, ...restRootFormContext } =
    rootUseFormContext(name)

  return {
    ...formContext.snippetCallbacks!,
    ...formContext.contentCallbacks!,
    forceBreakpoint: formContext.forceBreakpoint,
    focusedMolecule: formContext.focusedMolecule,
    excludedSettings: formContext.excludedSettings || [],
    customFonts: formContext.customFonts,
    usedFonts: formContext.usedFonts,
    ...restRootFormContext,
    setValues: useCallback(
      (values, options) =>
        setValues(values, {
          ...options,
          customData: {
            parentSnippetId:
              options?.customData?.parentSnippetId || parentSnippet?.id,
            parentSnippetPath:
              options?.customData?.parentSnippetPath || parentSnippet?.path,
            fieldName: name,
          },
        }),
      [stringify(parentSnippet), setValues],
    ),
    setValue: useCallback(
      (name, value, options) => {
        setValue(name, value, {
          ...options,
          customData: {
            parentSnippetId:
              options?.customData?.parentSnippetId || parentSnippet?.id,
            parentSnippetPath:
              options?.customData?.parentSnippetPath || parentSnippet?.path,
            fieldName: name,
          },
        })
      },
      [stringify(parentSnippet), setValue],
    ),
    unregister: useCallback(
      (names, options) =>
        unregister(names, {
          ...options,
          customData: {
            parentSnippetId:
              options?.customData?.parentSnippetId || parentSnippet?.id,
            parentSnippetPath:
              options?.customData?.parentSnippetPath || parentSnippet?.path,
            fieldName: name,
          },
        }),
      [stringify(parentSnippet), unregister],
    ),
    register: useCallback(
      (values, options) =>
        register(values, {
          ...options,
          customData: {
            parentSnippetId:
              options?.customData?.parentSnippetId || parentSnippet?.id,
            parentSnippetPath:
              options?.customData?.parentSnippetPath || parentSnippet?.path,
            fieldName: name,
          },
        }),
      [stringify(parentSnippet), register],
    ),
    snippets: getSnippetsByType({
      type: snippetsType,
      snippets: formContext.snippets,
    }),
    rawPlaceholders: getDataSubsetByName({
      name,
      data: formContext.rawPlaceholders,
    }),
    meta: getMetaSubsetByName({
      name: metaPath,
      data: formContext.meta,
    }),
    parentSnippet,
    allowSaveSnippet: Boolean(
      !parentSnippet?.id ||
        (parentSnippet?.id && !parentSnippet?.hasUnpublishedChanges),
    ),
  }
}
