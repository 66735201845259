import { Icon } from '@sceneio/ui-icons'
import {
  BreakpointType,
  RHFBreakpointDropdown,
} from '../BreakpointDropdown/BreakpointDropdown'
import clsx from 'clsx'
import { SnippetsDropdown } from '../SnippetsDropdown/SnippetsDropdown'
import { SNIPPET_TYPE } from '../../form/context/FormContext'

type HeaderSegmentPropType = {
  heading: string
  className?: string
  snippetsDropdown?: {
    show: boolean
    showSaveButton?: boolean
    snippetsType: SNIPPET_TYPE
    onCreateSnippet: (snippetName: string) => void
    onAssignSnippet: (id: string) => void
    onDeleteSnippet: (id: string) => void
  }
  breakpointDropdown?: {
    show: boolean
    activeBreakpoints: string[]
    onBreakpointClick: (arg0: BreakpointType) => void
  }
  addButton?: {
    show: boolean
    onClick: () => void
  }
  removeButton?: {
    show: boolean
    onClick: () => void
  }
  customChildren?: JSX.Element
}

export const HeaderSegment = ({
  heading,
  className,
  snippetsDropdown,
  breakpointDropdown,
  addButton,
  removeButton,
  customChildren,
}: HeaderSegmentPropType) => {
  return (
    <div
      className={clsx(
        'sc-header-segment tw-flex tw-items-center tw-justify-between tw-px-1',
        className,
      )}
    >
      <p className="tw-text-xs tw-font-semibold tw-leading-8 tw-cursor-default">
        {heading}
      </p>
      <div className="tw-flex tw-items-center tw-gap-2">
        {snippetsDropdown?.show && (
          <SnippetsDropdown
            popoverClassname="tw-max-w-[600px] tw-min-w-[350px]"
            onDeleteSnippet={snippetsDropdown.onDeleteSnippet}
            snippetsType={snippetsDropdown.snippetsType}
            onCreateSnippet={snippetsDropdown.onCreateSnippet}
            onAssignSnippet={snippetsDropdown.onAssignSnippet}
            showSaveButton={snippetsDropdown.showSaveButton}
          />
        )}
        {breakpointDropdown?.show && (
          <RHFBreakpointDropdown
            onSelect={breakpointDropdown.onBreakpointClick}
            disabledBreakpoints={breakpointDropdown.activeBreakpoints}
          />
        )}
        {addButton?.show && (
          <button type="button" onClick={addButton.onClick} className="tw-p-1">
            <Icon provider="phosphor" icon="Plus" />
          </button>
        )}

        {removeButton?.show && (
          <button
            type="button"
            onClick={removeButton.onClick}
            className="tw-p-1"
          >
            <Icon provider="phosphor" icon="Minus" />
          </button>
        )}
        {customChildren && customChildren}
      </div>
    </div>
  )
}
