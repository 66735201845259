export type FontWeightType = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900

export type FontsMapFontConfigVariantConfig = {
  fontAntialiased?: boolean
  allowedFontWeights: FontWeightType[]
}

type FontDefinitionType = {
  [key: string]: {
    title: string
    fontFamily: string
    url?: string
    config: FontsMapFontConfigVariantConfig
  }
}

export const systemFonts: FontDefinitionType = {
  helvetica: {
    title: 'Helvetica',
    fontFamily: 'helvetica, sans-serif',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  arial: {
    title: 'Arial',
    fontFamily: 'arial, sans-serif',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  times: {
    title: 'Times',
    fontFamily: '"Times Roman", serif',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  'times-new-roman': {
    title: 'Times New Roman',
    fontFamily: '"Times New Roman", "Times Roman", TimesNR, times, serif',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  courier: {
    title: 'Courier',
    fontFamily: 'courier, monospace',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  'courier-new': {
    title: 'Courier new',
    fontFamily: '"courier new", courier, monospace',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
}

export const externalFonts: FontDefinitionType = {
  'pp-neue-montreal': {
    title: 'PP Neue Montreal',
    fontFamily: 'pp-neue-montreal, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/pp-neue-montreal/v1/pp-neue-montreal.css',
    config: {
      allowedFontWeights: [100, 300, 400, 500, 600, 700],
      fontAntialiased: false,
    },
  },
  'general-sans': {
    title: 'General Sans',
    fontFamily: 'general-sans, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/general-sans/v1/general-sans.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  'ivypresto-headline': {
    title: 'IvyPresto Headline',
    fontFamily: 'ivypresto-headline, serif',
    url: 'https://use.typekit.net/zos2wen.css',
    config: {
      allowedFontWeights: [400, 700],
      fontAntialiased: false,
    },
  },
  cormorant: {
    title: 'Cormorant',
    fontFamily: 'Cormorant, serif',
    url: 'https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap',
    config: {
      allowedFontWeights: [400, 600, 700],
      fontAntialiased: false,
    },
  },
  'roboto-slab': {
    title: 'Roboto slab',
    fontFamily: '"Roboto slab", sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700.css',
    config: {
      allowedFontWeights: [300, 400, 700],
      fontAntialiased: false,
    },
  },
  roboto: {
    title: 'Roboto',
    fontFamily: 'Roboto, sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Roboto:300,400,700.css',
    config: {
      allowedFontWeights: [300, 400, 700],
      fontAntialiased: false,
    },
  },
  'work-sans': {
    title: 'Work sans',
    fontFamily: '"Work Sans", sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
    config: {
      allowedFontWeights: [300, 400, 500, 700],
      fontAntialiased: false,
    },
  },
  lora: {
    title: 'Lora',
    fontFamily: 'Lora, serif',
    url: 'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap',
    config: {
      allowedFontWeights: [400, 500, 700],
      fontAntialiased: false,
    },
  },
  comfortaa: {
    title: 'Comfortaa',
    fontFamily: 'Comfortaa, sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Comfortaa',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  montserrat: {
    title: 'Montserrat',
    fontFamily: 'Montserrat, sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,600;0,900;1,300;1,600;1,900&display=swap',
    config: {
      allowedFontWeights: [300, 600, 900],
      fontAntialiased: false,
    },
  },
  'open-sans': {
    title: 'Open sans',
    fontFamily: '"Open Sans", Arial, sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap',
    config: {
      allowedFontWeights: [300, 400, 600, 700, 800],
      fontAntialiased: false,
    },
  },
  anton: {
    title: 'Anton',
    fontFamily: 'Anton, sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Anton&display=swap',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  oswald: {
    title: 'Oswald',
    fontFamily: 'Oswald, sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Oswald:300,400',
    config: {
      allowedFontWeights: [300, 400],
      fontAntialiased: false,
    },
  },
  muli: {
    title: 'Muli',
    fontFamily: 'Muli,  sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Muli',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: true,
    },
  },
  'noto-sans': {
    title: 'Noto Sans',
    fontFamily: '"Noto Sans", sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap',
    config: {
      allowedFontWeights: [400, 700],
      fontAntialiased: false,
    },
  },
  raleway: {
    title: 'Raleway',
    fontFamily: '"Raleway", sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap',
    config: {
      allowedFontWeights: [400, 500, 600, 700, 800],
      fontAntialiased: true,
    },
  },
  lato: {
    title: 'Lato',
    fontFamily: 'Lato, sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Lato:400,700',
    config: {
      allowedFontWeights: [400, 700],
      fontAntialiased: false,
    },
  },
  manrope: {
    title: 'Manrope',
    fontFamily: 'Manrope, sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;700&display=swap',
    config: {
      allowedFontWeights: [300, 400, 700],
      fontAntialiased: false,
    },
  },
  merriweather: {
    title: 'Merriweather',
    fontFamily: 'Merriweather, serif',
    url: 'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,700;1,300;1,700&display=swap',
    config: {
      allowedFontWeights: [300, 700],
      fontAntialiased: false,
    },
  },
  livvic: {
    title: 'Livvic',
    fontFamily: 'Livvic, serif',
    url: 'https://fonts.googleapis.com/css2?family=Livvic:wght@600&display=swap',
    config: {
      allowedFontWeights: [600],
      fontAntialiased: false,
    },
  },
  // Default font, do not delete
  inter: {
    title: 'Inter',
    fontFamily: 'Inter, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/inter/v1/inter.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  interDisplay: {
    title: 'InterDisplay',
    fontFamily: 'InterDisplay, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/inter-display/v1/inter-display.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  interVariable: {
    title: 'InterVariable',
    fontFamily: 'InterVariable, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/inter-variable/v1/inter-variable.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  'space-grotesk': {
    title: 'Space Grotesk',
    fontFamily: '"Space Grotesk", sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500;700&display=swap',
    config: {
      allowedFontWeights: [500, 700],
      fontAntialiased: false,
    },
  },
  'bebas-neue': {
    title: 'Bebas Neue',
    fontFamily: '"Bebas Neue", sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  'playfair-display': {
    title: 'Playfair Display',
    fontFamily: '"Playfair Display", serif',
    url: 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap',
    config: {
      allowedFontWeights: [400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  anybody: {
    title: 'Anybody',
    fontFamily: 'anybody, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/anybody/v1/anybody.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  britney: {
    title: 'Britney',
    fontFamily: 'britney, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/britney/v1/britney.css',
    config: {
      allowedFontWeights: [300, 400, 700],
      fontAntialiased: false,
    },
  },
  'britney-variable': {
    title: 'Britney Variable',
    fontFamily: 'britney-variable, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/britney/v1/britney.css',
    config: {
      allowedFontWeights: [300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  'clash-display': {
    title: 'Clash Display',
    fontFamily: 'clash-display, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/clash-display/v1/clash-display.css',
    config: {
      allowedFontWeights: [200, 300, 400, 500, 600, 700],
      fontAntialiased: false,
    },
  },
  'clash-display-variable': {
    title: 'Clash Display Variable',
    fontFamily: 'clash-display-variable, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/clash-display/v1/clash-display.css',
    config: {
      allowedFontWeights: [200, 300, 400, 500, 600, 700],
      fontAntialiased: false,
    },
  },
  'clash-grotesk': {
    title: 'Clash Grotesk',
    fontFamily: 'clash-grotesk, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/clash-grotesk/v1/clash-grotesk.css',
    config: {
      allowedFontWeights: [200, 300, 400, 500, 600, 700],
      fontAntialiased: false,
    },
  },
  'clash-grotesk-varialbe': {
    title: 'Clash Grotesk Variable',
    fontFamily: 'clash-grotesk-variable, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/clash-grotesk/v1/clash-grotesk.css',
    config: {
      allowedFontWeights: [200, 300, 400, 500, 600, 700],
      fontAntialiased: false,
    },
  },
  fraunces: {
    title: 'Fraunces',
    fontFamily: 'fraunces, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/fraunces/v1/fraunces.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  frick: {
    title: 'Frick',
    fontFamily: 'frick, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/frick/v1/frick.css',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  'frick-condensed': {
    title: 'Frick Condesed',
    fontFamily: 'frick-condensed, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/frick-condensed/v1/frick-condensed.css',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  geom: {
    title: 'Geom',
    fontFamily: 'geom, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/geom/v1/geom.css',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  'getai-grotesk-display': {
    title: 'Getai Grotesk Display',
    fontFamily: 'getai-grotesk-display, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/getai-grotesk-display/v1/getai-grotesk-display.css',
    config: {
      allowedFontWeights: [900],
      fontAntialiased: false,
    },
  },
  'heal-the-web': {
    title: 'Heal the web',
    fontFamily: 'heal-the-web, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/heal-the-web/v1/heal-the-web.css',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  'heterodox-mono': {
    title: 'Heterodox Mono',
    fontFamily: 'heterodox-mono, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/heterodox-mono/v1/heterodox-mono.css',
    config: {
      allowedFontWeights: [300, 700],
      fontAntialiased: false,
    },
  },
  honey: {
    title: 'HONEY',
    fontFamily: 'honey, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/honey/v1/honey.css',
    config: {
      allowedFontWeights: [300],
      fontAntialiased: false,
    },
  },
  institute: {
    title: 'Institute',
    fontFamily: 'institute, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/institute/v1/institute.css',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  'instrument-serif': {
    title: 'Instrument Serif',
    fontFamily: 'instrument-serif, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/instrument-serif/v1/instrument-serif.css',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  'mazius-display': {
    title: 'Mazius Display',
    fontFamily: 'mazius-display, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/mazius-display/v1/mazius-display.css',
    config: {
      allowedFontWeights: [400, 700, 800],
      fontAntialiased: false,
    },
  },
  nightingale: {
    title: 'Nightingale',
    fontFamily: 'nightingale, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/nightingale/v1/nightingale.css',
    config: {
      allowedFontWeights: [300],
      fontAntialiased: false,
    },
  },
  'nyght-serif': {
    title: 'Nyght Serif',
    fontFamily: 'nyght-serif, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/nyght-serif/v1/nyght-serif.css',
    config: {
      allowedFontWeights: [300, 400, 500, 700, 900],
      fontAntialiased: false,
    },
  },
  pencerio: {
    title: 'Pencerio',
    fontFamily: 'pencerio, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/pencerio/v1/pencerio.css',
    config: {
      allowedFontWeights: [100, 400],
      fontAntialiased: false,
    },
  },
  ribes: {
    title: 'Ribes',
    fontFamily: 'ribes, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/ribes/v1/ribes.css',
    config: {
      allowedFontWeights: [300, 400, 900],
      fontAntialiased: false,
    },
  },
  sharpie: {
    title: 'Sharpie',
    fontFamily: 'sharpie, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/sharpie/v1/sharpie.css',
    config: {
      allowedFontWeights: [300, 400, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  'sharpie-variable': {
    title: 'Sharpie Variable',
    fontFamily: 'sharpie-variable, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/sharpie/v1/sharpie.css',
    config: {
      allowedFontWeights: [300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  sprat: {
    title: 'Sprat',
    fontFamily: 'sprat, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/sprat/v1/sprat.css',
    config: {
      allowedFontWeights: [100, 300, 400, 500, 700, 900],
      fontAntialiased: false,
    },
  },
  'sprat-condensed': {
    title: 'Sprat Condensed',
    fontFamily: 'sprat-condensed, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/sprat-condensed/v1/sprat-condensed.css',
    config: {
      allowedFontWeights: [100, 300, 400, 500, 700, 900],
      fontAntialiased: false,
    },
  },
  'sprat-extended': {
    title: 'Sprat Extended',
    fontFamily: 'sprat-extended, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/sprat-extended/v1/sprat-extended.css',
    config: {
      allowedFontWeights: [100, 300, 400, 500, 700, 900],
      fontAntialiased: false,
    },
  },
  superior: {
    title: 'Superior',
    fontFamily: 'superior, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/superior/v1/superior.css',
    config: {
      allowedFontWeights: [100, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  'superior-mono': {
    title: 'Superior Mono',
    fontFamily: 'superior-mono, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/superior-mono/v1/superior-mono.css',
    config: {
      allowedFontWeights: [400, 500, 600, 700],
      fontAntialiased: false,
    },
  },
  telma: {
    title: 'Telma',
    fontFamily: 'telma, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/telma/v1/telma.css',
    config: {
      allowedFontWeights: [300, 400, 500, 700, 900],
      fontAntialiased: false,
    },
  },
  'telma-variable': {
    title: 'Telma Variable',
    fontFamily: 'telma-variable, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/telma/v1/telma.css',
    config: {
      allowedFontWeights: [300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  zarathustra: {
    title: 'Zarathustra',
    fontFamily: 'zarathustra, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/zarathustra/v1/zarathustra.css',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  absans: {
    title: 'Absans',
    fontFamily: 'absans, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/absans/v1/absans.css',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  'apfel-grotezk': {
    title: 'Apfel Grotezk',
    fontFamily: 'apfel-grotezk, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/apfel-grotezk/v1/apfel-grotezk.css',
    config: {
      allowedFontWeights: [300, 400, 500, 700, 900],
      fontAntialiased: false,
    },
  },
  aspekta: {
    title: 'Aspekta',
    fontFamily: 'aspekta, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/aspekta/v1/aspekta.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  evening: {
    title: 'Evening',
    fontFamily: 'evening, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/evening/v1/evening.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  'optician-sans': {
    title: 'Optician Sans',
    fontFamily: 'optician-sans, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/optician-sans/v1/optician-sans.css',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  'overused-grotesk': {
    title: 'Overused Grotesk',
    fontFamily: 'overused-grotesk, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/overused-grotesk/v1/overused-grotesk.css',
    config: {
      allowedFontWeights: [300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  panamera: {
    title: 'Panamera',
    fontFamily: 'panamera, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/panamera/v1/panamera.css',
    config: {
      allowedFontWeights: [100, 300, 400, 500, 700, 900],
      fontAntialiased: false,
    },
  },
  'prodigy-sans': {
    title: 'Prodigy Sans',
    fontFamily: 'prodigy-sans, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/prodigy-sans/v1/prodigy-sans.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  'uncut-sans': {
    title: 'Uncut Sans',
    fontFamily: 'uncut-sans, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/uncut-sans/v1/uncut-sans.css',
    config: {
      allowedFontWeights: [300, 400, 500, 600, 700],
      fontAntialiased: false,
    },
  },
  'violet-sans': {
    title: 'Violet Sans',
    fontFamily: 'violet-sans, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/violet-sans/v1/violet-sans.css',
    config: {
      allowedFontWeights: [400],
      fontAntialiased: false,
    },
  },
  'outward-block': {
    title: 'Outward Block',
    fontFamily: 'outward-block, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/outward-block/v1/outward-block.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  'outward-borders': {
    title: 'Outward Borders',
    fontFamily: 'outward-borders, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/outward-borders/v1/outward-borders.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  'outward-round': {
    title: 'Outward Borders',
    fontFamily: 'outward-round, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/outward-round/v1/outward-round.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  rokkitt: {
    title: 'Rokkitt',
    fontFamily: 'rokkitt, serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/rokkitt/v1/rokkitt.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  subjectivity: {
    title: 'subjectivity',
    fontFamily: 'subjectivity, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/subjectivity/v1/subjectivity.css',
    config: {
      allowedFontWeights: [100, 300, 400, 500, 700, 800, 900],
      fontAntialiased: false,
    },
  },
  'neue-plak': {
    title: 'Neue Plak',
    fontFamily: 'neue-plak, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/neue-plak/v1/neue-plak.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 600, 700, 900],
      fontAntialiased: false,
    },
  },
  'neue-plak-extended': {
    title: 'Neue Plak Extended',
    fontFamily: 'neue-plak-extended, sans-serif',
    url: 'https://prod-v3.cdn.scene.io/static/assets/fonts/neue-plak-extended/v1/neue-plak-extended.css',
    config: {
      allowedFontWeights: [100, 200, 300, 400, 600, 700, 900],
      fontAntialiased: false,
    },
  },
}

export type FontType = {
  readonly title: string
  readonly fontFamily: string
  readonly url?: string
  readonly config: FontsMapFontConfigVariantConfig
}

export type FontsMap = {
  [prop: string]: FontType
}

export const fontsConfig = {
  ...systemFonts,
  ...externalFonts,
} satisfies FontsMap

export const fontsPreviewSpriteYCoordsByFontFamily = {
  'helvetica, sans-serif': 0,
  'arial, sans-serif': -17,
  '"Times Roman", serif': -34,
  '"Times New Roman", "Times Roman", TimesNR, times, serif': -51,
  'courier, monospace': -68,
  '"courier new", courier, monospace': -85,
  'pp-neue-montreal, sans-serif': -102,
  'general-sans, sans-serif': -119,
  'ivypresto-headline, serif': -136,
  'Cormorant, serif': -153,
  '"Roboto slab", sans-serif': -170,
  'Roboto, sans-serif': -187,
  '"Work Sans", sans-serif': -204,
  'Lora, serif': -221,
  'Comfortaa, sans-serif': -238,
  'Montserrat, sans-serif': -255,
  '"Open Sans", Arial, sans-serif': -272,
  'Anton, sans-serif': -289,
  'Oswald, sans-serif': -306,
  'Muli,  sans-serif': -323,
  '"Noto Sans", sans-serif': -340,
  '"Raleway", sans-serif': -357,
  'Lato, sans-serif': -374,
  'Manrope, sans-serif': -391,
  'Merriweather, serif': -408,
  'Livvic, serif': -425,
  'Inter, sans-serif': -442,
  'InterDisplay, sans-serif': -459,
  'InterVariable, sans-serif': -476,
  '"Space Grotesk", sans-serif': -493,
  '"Bebas Neue", sans-serif': -510,
  '"Playfair Display", serif': -527,
  'anybody, sans-serif': -544,
  'britney, sans-serif': -561,
  'britney-variable, sans-serif': -578,
  'clash-display, sans-serif': -595,
  'clash-display-variable, sans-serif': -612,
  'clash-grotesk, sans-serif': -629,
  'clash-grotesk-variable, sans-serif': -646,
  'fraunces, serif': -663,
  'frick, sans-serif': -680,
  'frick-condensed, sans-serif': -697,
  'geom, sans-serif': -714,
  'getai-grotesk-display, sans-serif': -731,
  'heal-the-web, serif': -748,
  'heterodox-mono, serif': -765,
  'honey, sans-serif': -782,
  'institute, sans-serif': -799,
  'instrument-serif, serif': -816,
  'mazius-display, serif': -833,
  'nightingale, serif': -850,
  'nyght-serif, serif': -867,
  'pencerio, serif': -884,
  'ribes, sans-serif': -901,
  'sharpie, sans-serif': -918,
  'sharpie-variable, sans-serif': -935,
  'sprat, serif': -952,
  'sprat-condensed, serif': -969,
  'sprat-extended, serif': -986,
  'superior, sans-serif': -1003,
  'superior-mono, sans-serif': -1020,
  'telma, serif': -1037,
  'telma-variable, serif': -1054,
  'zarathustra, serif': -1071,
  'absans, serif': -1088,
  'apfel-grotezk, sans-serif': -1105,
  'aspekta, sans-serif': -1122,
  'evening, sans-serif': -1139,
  'optician-sans, sans-serif': -1156,
  'overused-grotesk, sans-serif': -1173,
  'panamera, sans-serif': -1190,
  'prodigy-sans, sans-serif': -1207,
  'uncut-sans, sans-serif': -1224,
  'violet-sans, sans-serif': -1241,
  'outward-block, sans-serif': -1258,
  'outward-borders, sans-serif': -1275,
  'outward-round, sans-serif': -1292,
  'rokkitt, serif': -1309,
  'subjectivity, sans-serif': -1326,
  'neue-plak, sans-serif': -1343,
  'neue-plak-extended, sans-serif': -1360,
}
