import React, { CSSProperties } from 'react'
import { clsx } from 'clsx'
import * as Form from '@radix-ui/react-form'
import { Icon } from '@sceneio/ui-icons'
import type { Icons } from '@sceneio/ui-icons'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { StateType } from '../StateTabs/StateTabs'
import { BreakpointType } from '../BreakpointDropdown/BreakpointDropdown'
import { resolveBorderValueString } from './borderSelectHelpers'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useFormContext } from '../../form/context/FormContext'

export type RHFBorderSelectPropType = {
  label?: string
  className?: string
  hidden?: boolean
  style?: CSSProperties
  breakpoint?: BreakpointType | 'default'
  state?: StateType
  options?: Readonly<string[]>
  valuesPath: string
}

const BORDER_ICONS_MAP: Record<string, Icons['scene']> = {
  top: 'sides-t',
  left: 'sides-l',
  bottom: 'sides-b',
  right: 'sides-r',
}

export const RHFBorderSelect = ({
  hidden,
  style,
  className,
  label,
  options = [],
  breakpoint = 'default',
  state = 'default',
  valuesPath,
}: RHFBorderSelectPropType) => {
  const { setValue, values, error, placeholders, forceBreakpoint } =
    useFormContext()

  const containerClasses = clsx(
    'sc-rhf-border-select tw-container tw-text-xs',
    {
      'tw-hidden': hidden,
    },
    className,
  )

  return (
    <Form.Field className={containerClasses} style={style} name={valuesPath}>
      <Form.Label
        className={clsx(
          'tw-bg-form-field-bg tw-rounded tw-flex tw-flex-wrap tw-items-center hover:tw-outline hover:tw-outline-1 hover:tw-outline-offset-[-1px] hover:tw-outline-border-color focus-within:tw-outline-border-color focus-within:tw-outline-1 focus-within:tw-outline-offset-[-1px] focus-within:tw-outline',
          {
            'tw-bg-white': forceBreakpoint && breakpoint !== forceBreakpoint,
          },
        )}
      >
        {label && (
          <div className="tw-flex-1 tw-min-w-0 tw-py-2 tw-px-2 text-label-color">
            {label}
          </div>
        )}
        <DropdownMenu.Root>
          <DropdownMenu.Trigger
            tabIndex={1}
            className="tw-flex tw-items-center tw-justify-between focus-visible:tw-outline-none tw-py-2 tw-pr-1 tw-pl-2 tw-gap-1 tw-w-full"
          >
            <Icon
              provider="scene"
              size={'1rem'}
              icon={
                `sides-${resolveBorderValueString(
                  { ...placeholders, ...values },
                  state,
                  breakpoint,
                  valuesPath,
                )}` as Icons['scene']
              }
            />
            {(!forceBreakpoint ||
              (forceBreakpoint && breakpoint !== 'default')) && (
              <Icon
                provider="phosphor"
                icon="CaretDown"
                size="1rem"
                className="tw-mr-1"
              />
            )}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content className="tw-overflow-hidden tw-bg-white tw-text-xs tw-rounded tw-border tw-border-border-color tw-p-1 tw-min-w-[130px] tw-z-10 tw-my-px">
            {options.map((side) => {
              const name = `${valuesPath}.${state}.--border-${breakpoint}-${side}-style-${state}`
              const val = values[name] ?? placeholders[name] ?? 'none'
              return (
                <DropdownMenu.CheckboxItem
                  key={side}
                  onSelect={(e) => e.preventDefault()}
                  className="tw-flex tw-justify-between tw-items-center tw-p-2 hover:tw-outline-none focus-visible:tw-outline-none hover:tw-bg-form-field-bg tw-rounded"
                  checked={val !== 'none'}
                  onCheckedChange={(checked) => {
                    setValue(
                      name,
                      checked
                        ? values[
                            `${valuesPath}.${state}.--border-${breakpoint}-style-${state}`
                          ] || 'solid'
                        : 'none',
                    )
                  }}
                >
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <Icon
                      provider="scene"
                      icon={BORDER_ICONS_MAP[side]}
                      size={'1rem'}
                    />
                    <span className="tw-capitalize">{side}</span>
                  </div>
                  <DropdownMenu.ItemIndicator>
                    <Icon provider="phosphor" icon="Check" />
                  </DropdownMenu.ItemIndicator>
                </DropdownMenu.CheckboxItem>
              )
            })}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Form.Label>
      {error && <ErrorMessage message={error?.message} />}
    </Form.Field>
  )
}
