import { BlockComponentMeta, BlockMeta } from '../blockCommonTypes'
import { blockDefaultConfigFactory } from '../helpers'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../components/Text/constants/globalTextCssVariables'
import { containerComponentFormRegisterValuesFactory } from '../../components/Container/form/ContainerComponentForm'
import { randomString } from '@sceneio/tools'
import type { BlockComponent, TimelineBlock } from '@sceneio/schemas/lib/types'
import { blockContainerDefaults } from '../../components/BlockContainer/blockContainerMeta'
import { DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES } from '../../components/Button/constants/globalButtonCssVariables'
import { timelineItemDefaults } from '../../components/TimelineItem/timelineItemMeta'
import {
  blockLayoutAdditionalFormFieldRenderer,
  buttonContainerLayoutAdditionalFormFieldRenderer,
} from '../Hero/SplitHero/form/additionalFormFieldRenderers'
import { textToLexical } from '@sceneio/wysiwyg-utils'

const TIMELINE_ITEM_DEFAULTS = timelineItemDefaults.config
const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

export const buttonSampleFactory = (data = {}) => ({
  id: randomString(8),
  children: textToLexical('Button', 'array-item'),
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
  ...data,
})

const timelineItemSampleFactory = (data = {}) => ({
  id: randomString(),
  ...TIMELINE_ITEM_DEFAULTS,
  ...data,
})

export const timelineComponentMeta: BlockComponentMeta<TimelineBlock> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      swapOrder: false,
      columnRatio: 6,
    } as BlockComponent & {
      swapOrder?: boolean
      columnRatio: number
    },
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: blockLayoutAdditionalFormFieldRenderer,
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      responsiveConfig: {
        default: {
          maxWidth: 'screen',
          rowGap: '3.5rem',
          columnGap: '2.5rem',
        },
      },
      padding: BLOCK_CONTAINER_DEFAULTS.padding,
    },
    type: 'blockContainer',
  },
  tagline: {
    path: 'components.tagline',
    name: 'Tagline',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.tagline',
      },
    ],
    defaultConfig: {
      children: textToLexical('Tagline'),
      as: 'p',
      margin: {
        data: {
          '--margin-default-bottom': '1.5rem',
        },
      },
      font: {
        data: {
          default: {
            '--font-default-weight-default': 'bold',
          },
        },
      },
    },
    type: 'text',
  },
  heading: {
    path: 'components.heading',
    name: 'Heading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.heading',
      },
    ],
    defaultConfig: {
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
      children: textToLexical('Medium length section heading goes here', 'h2'),
      as: 'h2',
      margin: {
        data: {
          '--margin-default-bottom': '1.5rem',
        },
      },
    },
    type: 'text',
  },
  text: {
    path: 'components.text',
    name: 'Text',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.text',
        editorOptions: {
          include: ['table', 'list'],
        },
      },
    ],
    defaultConfig: {
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
      ),
      as: 'p',
      margin: {
        data: {
          '--margin-default-bottom': '2rem',
        },
      },
    },
    type: 'text',
  },
  buttons: {
    path: 'components.buttons',
    components: {
      items: {
        isArrayComponent: true,
        path: 'items',
        name: 'Button',
        type: 'button',
        disableToggleShow: true,
        sampleFactory: () => buttonSampleFactory(),
      },
    },
    inlineEditors: [
      {
        editorType: 'buttonEditor',
        editorPath: 'components.buttons.items',
        editorOptions: {
          isItemArray: true,
          sampleFactory: () => buttonSampleFactory(),
        },
      },
    ],
    defaultConfig: {
      items: [
        buttonSampleFactory(),
        buttonSampleFactory({
          children: textToLexical('2nd Button', 'array-item'),
          ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES,
        }),
      ],
      show: true,
      gap: {
        data: {
          [`--row-gap-default`]: '1rem',
          [`--column-gap-default`]: '1rem',
        },
      },
      flow: {
        data: {
          '--flow-default': 'row',
          '--flow-xs': 'column',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'flex-start',
        },
      },
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
    },
    name: 'Buttons',
    type: 'container',
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
        },
      },
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
  timeline: {
    path: 'components.timeline',
    name: 'Timeline',
    type: 'timeline',
    disableToggleShow: true,
    components: {
      items: {
        isArrayComponent: true,
        sampleFactory: timelineItemSampleFactory,
        path: 'items',
        name: 'Timeline Item',
        type: 'timelineItem',
        components: {
          contentContainer: {
            type: 'container',
            name: 'Content',
            path: 'components.contentContainer',
            configEditor: {
              formProps: {
                name: 'group',
                registerValues:
                  containerComponentFormRegisterValuesFactory('group'),
              },
            },
          },
          heading: {
            type: 'text',
            name: 'Heading',
            path: 'components.heading',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.heading',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          text: {
            type: 'text',
            name: 'Text',
            path: 'components.text',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.text',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          image: {
            type: 'image',
            name: 'Image',
            path: 'components.image',
          },
        },
      },
    },
    defaultConfig: {
      items: [
        timelineItemSampleFactory(),
        timelineItemSampleFactory({
          components: {
            ...TIMELINE_ITEM_DEFAULTS.components,
            heading: {
              ...TIMELINE_ITEM_DEFAULTS.components.heading,
              children: textToLexical('Subheading two', 'h4'),
            },
          },
        }),
        timelineItemSampleFactory({
          components: {
            ...TIMELINE_ITEM_DEFAULTS.components,
            heading: {
              ...TIMELINE_ITEM_DEFAULTS.components.heading,
              children: textToLexical('Subheading three', 'h4'),
            },
          },
        }),
        timelineItemSampleFactory({
          components: {
            ...TIMELINE_ITEM_DEFAULTS.components,
            heading: {
              ...TIMELINE_ITEM_DEFAULTS.components.heading,
              children: textToLexical('Subheading four', 'h4'),
            },
          },
        }),
      ],
    },
    configEditor: {
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
}

export const timelineBlockMeta: BlockMeta<TimelineBlock> = {}

export const timelineDefaultConfig = blockDefaultConfigFactory<TimelineBlock>({
  componentMeta: timelineComponentMeta,
  blockMeta: timelineBlockMeta,
  version: 1,
})
