import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'

export const videoComponentMeta: ComponentMeta<'video'> = {
  defaultConfig: {
    language: 'en',
    showPoster: true,
    showPlayButton: true,
    showControls: true,
    responsiveConfig: {
      default: {
        posterType: 'image',
        src: 'https://cdn.scene.io/static/assets/videos/placeholders/video-sample.mp4',
        posterImageSrc:
          'https://assets.scene.io/global/placeholders/light_1400x1080.png',
        posterVideoSrc:
          'https://cdn.scene.io/static/assets/videos/placeholders/video-sample.mp4',
      },
    },
    className: '',
    show: true,
    duration: {
      show: true,
      font: {
        data: {
          default: {
            '--font-default-family-default': 'arial, sans-serif',
          },
        },
      },
      margin: {
        data: {
          '--margin-default-top': '80px',
        },
      },
      textShadow: {
        responsiveConfig: {
          default: {
            default: [
              {
                x: '0px',
                y: '0px',
                blur: '3px',
                color: '#000',
              },
            ],
          },
        },
      },
      color: {
        data: {
          default: {
            '--color-default-default': '#fff',
          },
        },
      },
    },
  },
}

export const videoDefaults = componentDefaultConfigFactory<'video'>({
  componentMeta: videoComponentMeta,
})
