export function convertJMESPathToArrayPath(
  path: string,
): Array<string | number> {
  if (typeof path !== 'string') throw Error('Property `path` is not defined')
  return path
    .replace(/\[/g, '.')
    .replace(/\]/g, '')
    .split('.')
    .map((key) => {
      if (key === Number(key).toString()) {
        return Number(key)
      }

      return key
    })
    .filter((val) => val !== '')
}
