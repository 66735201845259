import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { AppDispatch, RootState } from '../../store'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
  SnippetsSliceType,
  CreateSnippetQueryVarsType,
  UpdateSnippetQueryVarsType,
  DetachSnippetQueryVarsType,
  DiscardSnippetQueryVarsType,
  IssueSnippetQueryVarsType,
  AssignSnippetQueryVarsType,
  CreateSnippetRawArgsType,
  DeleteSnippetQueryVarsType,
} from './snippetsSliceTypes'
import {
  createGraphqlAsyncThunk,
  createGraphqlAsyncThunkByDocument,
} from '../../helpers/createGraphqlAsyncThunk'
import { createAppendCidThunk } from '../../helpers/createAppendCidThunk'
import { BlockType, ContentType } from '../content/contentSliceTypes'
import {
  getSnippetDependencyByRefs,
  preprocessSnippetFromEntity,
} from '@sceneio/snippets-tools'
import {
  APPEND_REFERENCE_METHOD_BY_TYPE_MAP,
  resolveUpdateContentBlock,
  updateContentData,
} from '../content/contentSlice'
import {
  deleteReference,
  getReferencePath,
  referencesCleanup,
} from '@sceneio/referencing-tools'
import { appendSnippetReference } from '@sceneio/referencing-tools/lib/appendReference'
import {
  assocJMESPath,
  changeObjectPropertyByJmespath,
  normalizeJMESPath,
  randomString,
  removeEmptyValues,
  removeUndefinedValues,
} from '@sceneio/tools'
import { search } from 'jmespath'
import { flattenUpdate } from '../../helpers/flattenUpdate'
import { SnippetType } from '@sceneio/snippets-tools/lib/snippetsTypes'
import { validateSnippet } from '@sceneio/schemas'
import { errorNotification } from '@sceneio/cms-notifications'
import { thunkReject } from '../../helpers/thunkReject'
import { asyncThunkFetcher } from '../../helpers/asyncThunkFetcher'

export * from './snippetSliceSelectors'

type GraphqlThunkData<T> = {
  queryVariables: T
  thunkOptions?: {
    onSuccess?: () => void
    shouldReplaceValue?: boolean
    shouldRemoveEmptyValues?: boolean
  }
}

// ---------------
// Initial State
// ---------------
export const initialState: SnippetsSliceType = {
  entities: [],
  status: 'idle',
  error: null,
  requests: {
    updateSnippetRequestId: '',
  },
} as SnippetsSliceType

// ---------------
// Thunks
// ---------------

export const fetchSnippets = createGraphqlAsyncThunkByDocument<
  'SnippetsDataQueryDocument',
  'snippets'
>()('snippets/fetchData', async (_, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'SnippetsDataQueryDocument',
    selector: 'snippets',
    variables: {},
    thunkAPI,
  })
})

export const createSnippet = createGraphqlAsyncThunkByDocument<
  'CreateSnippetMutationDocument',
  'createSnippet',
  CreateSnippetQueryVarsType
>()('snippets/snippet/create', async ({ queryVariables }, thunkAPI) => {
  const {
    cid,
    entity: givenEntity,
    entityType,
    targetPath,
    snippet: givenSnippet,
    basePath,
  } = queryVariables

  const snippets = thunkAPI.getState().snippets.entities

  const snippetTargetPathWithBasePath = basePath
    ? `${basePath}.${targetPath}`
    : targetPath

  const { snippet, entity } = preprocessSnippetFromEntity({
    snippet: {
      id: cid!,
      ...givenSnippet,
      snippetTargetPath: snippetTargetPathWithBasePath,
    },
    entity: givenEntity,
    snippets,
    snippetReferenceBasePath: 'data.value',
  })

  const { isValid, error } = validateSnippet(snippet)

  if (!isValid) {
    errorNotification({
      content: 'Invalid snippet data',
      log: {
        message: '[createSnippet]: Snippet data validation failed',
        data: {
          dataToValidate: snippet,
          validationError: error,
          thunkQueryVariables: queryVariables,
        },
      },
    })

    return thunkReject({
      code: 'invalid_data',
      message: 'Invalid snippet data',
      thunkAPI,
      rejectQueries: ['SNIPPETS'],
    })
  }

  const snippetReferenceResolveMethod = snippet?.meta?.shouldMergeData
    ? 'MERGE'
    : 'REPLACE'

  return await asyncThunkFetcher({
    query: 'CreateSnippetMutationDocument',
    selector: 'createSnippet',
    variables: {
      cid: snippet.id,
      name: snippet.name,
      data: snippet.data,
      meta: snippet.meta,
      type: snippet.type,
      references: snippet.references,
    },
    thunkAPI,
    rejectQueries: ['SNIPPETS'],
  }).then((createdSnippet) => {
    if (entityType === 'SNIPPET') {
      const snippetEntity = entity as SnippetType
      const referencePath = getReferencePath({
        configPath: snippetTargetPathWithBasePath,
        data: snippetEntity!,
      })

      const updatedReferences = appendSnippetReference({
        references: snippetEntity.references || [],
        data: {
          id: createdSnippet.id,
          path: referencePath,
          resolveMethod: snippetReferenceResolveMethod,
        },
      })

      const snippetData = givenSnippet?.data?.value

      const dataAlreadyExists = search(
        snippetData,
        normalizeJMESPath(snippetTargetPathWithBasePath),
      )

      let updatedSnippetData = snippetData

      if (dataAlreadyExists) {
        updatedSnippetData = changeObjectPropertyByJmespath(
          snippetEntity,
          snippetTargetPathWithBasePath,
          snippetData,
          {
            shouldMergeValue: snippet?.meta?.shouldMergeData,
          },
        )
      } else {
        updatedSnippetData = assocJMESPath(
          snippetTargetPathWithBasePath,
          snippetData,
          snippetEntity,
        )
      }

      thunkAPI.dispatch(
        updateSnippet({
          queryVariables: {
            value: updatedSnippetData?.data?.value,
            snippetId: snippetEntity.id!,
            referencesToReplace: updatedReferences,
          },
          thunkOptions: {
            shouldReplaceValue: true,
          },
        }),
      )
    }

    if (entityType === 'CONTENTBLOCK') {
      const blockEntity = entity as BlockType
      const blockConfig = blockEntity.config || {}

      const referencePath = getReferencePath({
        configPath: snippetTargetPathWithBasePath,
        data: { config: blockConfig },
      })

      const updatedReferences = appendSnippetReference({
        references: blockEntity.references,
        data: {
          id: createdSnippet.id,
          path: referencePath,
          resolveMethod: snippetReferenceResolveMethod,
        },
      })

      const snippetData = givenSnippet?.data?.value

      const dataAlreadyExists = search(
        blockEntity,
        normalizeJMESPath(snippetTargetPathWithBasePath),
      )

      let updatedBlockData = blockEntity

      if (dataAlreadyExists) {
        updatedBlockData = changeObjectPropertyByJmespath(
          blockEntity,
          snippetTargetPathWithBasePath,
          snippetData,
          {
            shouldMergeValue: snippet?.meta?.shouldMergeData,
          },
        )
      } else {
        updatedBlockData = assocJMESPath(
          snippetTargetPathWithBasePath,
          snippetData,
          blockEntity,
        )
      }
      // TODO fix typescript
      thunkAPI.dispatch(
        resolveUpdateContentBlock(
          {
            configPath: targetPath,
            configValue: search(
              updatedBlockData,
              normalizeJMESPath(snippetTargetPathWithBasePath),
            ),
            blockId: blockEntity.id,
            referencesToReplace: updatedReferences,
          },
          {
            shouldReplaceValue: true,
          },
        ),
      )
    }

    if (entityType === 'CONTENT') {
      const contentEntity = entity as ContentType
      const layoutConfig = contentEntity.data || {}

      const referencePath = getReferencePath({
        configPath: snippetTargetPathWithBasePath,
        data: { config: layoutConfig },
      })

      const updatedReferences = appendSnippetReference({
        references: contentEntity.references!,
        data: {
          id: createdSnippet.id,
          path: referencePath,
          resolveMethod: snippetReferenceResolveMethod,
        },
      })

      const snippetData = givenSnippet?.data?.value

      const dataAlreadyExists = search(
        contentEntity,
        normalizeJMESPath(snippetTargetPathWithBasePath),
      )

      let updatedContentData = contentEntity

      if (dataAlreadyExists) {
        updatedContentData = changeObjectPropertyByJmespath(
          contentEntity,
          snippetTargetPathWithBasePath,
          snippetData,
          {
            shouldMergeValue: snippet?.meta?.shouldMergeData,
          },
        )
      } else {
        updatedContentData = assocJMESPath(
          snippetTargetPathWithBasePath,
          snippetData,
          contentEntity,
        )
      }

      thunkAPI.dispatch(
        updateContentData({
          queryVariables: {
            configPath: `config`,
            configValue: search(updatedContentData, 'data.config'),
            referencesToReplace: updatedReferences,
          },
        }),
      )
    }

    return createdSnippet
  })
})

export const createSnippetRaw = createGraphqlAsyncThunkByDocument<
  'CreateSnippetMutationDocument',
  'createSnippet'
>()('snippets/snippetRaw/create', async ({ queryVariables }, thunkAPI) => {
  const { isValid, error } = validateSnippet(queryVariables)

  if (!isValid) {
    errorNotification({
      content: 'Invalid snippet data',
      log: {
        message: '[createSnippetRaw]: Snippet data validation failed',
        data: {
          dataToValidate: queryVariables,
          validationError: error,
          thunkQueryVariables: queryVariables,
        },
      },
    })

    return thunkReject({
      code: 'invalid_data',
      message: 'Invalid snippet data',
      thunkAPI,
      rejectQueries: ['SNIPPETS'],
    })
  }

  return await asyncThunkFetcher({
    query: 'CreateSnippetMutationDocument',
    selector: 'createSnippet',
    variables: {
      cid: queryVariables.cid,
      name: queryVariables.name,
      data: queryVariables.data,
      meta: queryVariables.meta,
      type: queryVariables.type,
      references: queryVariables.references,
    },
    thunkAPI,
    rejectQueries: ['SNIPPETS'],
  })
})

export const updateSnippet = createGraphqlAsyncThunkByDocument<
  'UpdateSnippetMutationDocument',
  'updateSnippet',
  UpdateSnippetQueryVarsType
>()(
  'snippets/snippet/update',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const {
      snippetId,
      name,
      value,
      referencesToProcess = [],
      referencesToReplace,
    } = queryVariables

    const snippet = thunkAPI
      .getState()
      .snippets.entities.find((snippet) => snippet.id === snippetId)

    if (!snippet) {
      return thunkReject({
        code: 'data_not_found',
        message: 'Snippet not found',
        thunkAPI,
        rejectQueries: ['SNIPPETS'],
      })
    }

    let snippetData = snippet?.data || {}

    if (value !== undefined) {
      if (thunkOptions?.shouldReplaceValue) {
        snippetData = assocJMESPath('value', value, snippetData) as Record<
          string,
          any
        >
      } else {
        snippetData = flattenUpdate(snippetData, { value: value }, '')
      }

      if (thunkOptions?.shouldRemoveEmptyValues) {
        snippetData = assocJMESPath(
          'value',
          removeEmptyValues(search(snippetData, 'value')),
          snippetData,
        )
      }
    }

    const dataToValidate = {
      ...snippet,
      data: snippetData,
    }

    const { isValid, error } = validateSnippet(dataToValidate)

    if (!isValid) {
      errorNotification({
        content: 'Invalid snippet data',
        log: {
          message: '[updateSnippet]: Snippet data validation failed',
          data: {
            dataToValidate: snippet,
            validationError: error,
            thunkQueryVariables: queryVariables,
          },
        },
      })

      return thunkReject({
        code: 'invalid_data',
        message: 'Invalid snippet data',
        thunkAPI,
        rejectQueries: ['SNIPPETS'],
      })
    }

    const { references: snippetReferences } = snippet

    // process references
    let updatedReferences = snippetReferences
    referencesToProcess.forEach(({ type, operation, data }) => {
      const referencePath = getReferencePath({
        configPath: data.path!,
        data: { data: snippetData },
      })

      if (operation === 'delete') {
        updatedReferences = deleteReference({
          references: updatedReferences,
          path: referencePath,
        })
      }

      if (operation === 'add') {
        const appendReferencesMethod =
          APPEND_REFERENCE_METHOD_BY_TYPE_MAP[
            type as keyof typeof APPEND_REFERENCE_METHOD_BY_TYPE_MAP
          ]

        if (appendReferencesMethod) {
          updatedReferences = appendReferencesMethod({
            references: snippetReferences,
            data: {
              id: data.id!,
              path: referencePath,
            },
          })
        }
      }
    })

    // final references cleanup
    updatedReferences = referencesCleanup({
      references: updatedReferences,
      data: { data: snippetData },
    })

    return await asyncThunkFetcher({
      query: 'UpdateSnippetMutationDocument',
      selector: 'updateSnippet',
      variables: removeUndefinedValues({
        id: snippet.id,
        name: name,
        data: snippetData,
        references:
          (referencesToReplace ? referencesToReplace : updatedReferences) || [],
      }),
      rejectQueries: ['SNIPPETS'],
      thunkAPI,
    })
  },
)

export const deleteSnippet = createGraphqlAsyncThunkByDocument<
  'DeleteSnippetMutationDocument',
  'deleteSnippet',
  DeleteSnippetQueryVarsType
>()('snippets/snippet/delete', async ({ queryVariables }, thunkAPI) => {
  const { id } = queryVariables

  return await asyncThunkFetcher({
    query: 'DeleteSnippetMutationDocument',
    selector: 'deleteSnippet',
    variables: {
      id,
    },
    rejectQueries: ['SNIPPETS'],
    thunkAPI,
  })
})

export const detachSnippet = createGraphqlAsyncThunk<
  SnippetType | BlockType | ContentType | void,
  DetachSnippetQueryVarsType
>('snippets/snippet/detach', async ({ queryVariables }, thunkAPI) => {
  const { targetId, sourceId, entityType, targetPath } = queryVariables

  const snippet = thunkAPI
    .getState()
    .snippets.entities.find((snippet) => snippet.id === targetId)

  if (!snippet) {
    return thunkReject({
      code: 'data_not_found',
      message: 'Snippet not found',
      thunkAPI,
      rejectQueries: ['SNIPPETS'],
    })
  }

  const snippetDataValue = snippet?.data?.value

  if (entityType === 'CONTENTBLOCK') {
    const contentBlockEntity = thunkAPI
      .getState()
      .content.entity?.contentBlocks?.find(
        (contentBlock) => contentBlock.id === sourceId,
      )

    if (contentBlockEntity) {
      // remove related snippet reference

      const referencePath = getReferencePath({
        configPath: targetPath,
        data: contentBlockEntity,
      })

      const updatedReferences = contentBlockEntity?.references?.filter(
        ({ typeTo, pathFrom }) =>
          !(typeTo === 'SNIPPET' && pathFrom === referencePath),
      )

      // update content block data to match latest snippet data values
      const contentBlockConfig =
        contentBlockEntity.reusableContentBlockDraft?.config ||
        contentBlockEntity.config

      const updatedContentBlockData = changeObjectPropertyByJmespath<BlockType>(
        { ...contentBlockEntity, config: contentBlockConfig },
        targetPath,
        snippetDataValue,
        {
          shouldMergeValue: snippet?.meta?.shouldMergeData,
        },
      )

      // TODO fix typescript
      thunkAPI.dispatch(
        resolveUpdateContentBlock(
          {
            configPath: '',
            configValue: updatedContentBlockData.config,
            blockId: contentBlockEntity?.id,
            referencesToReplace: updatedReferences,
          },
          {
            shouldReplaceValue: true,
          },
        ),
      )
    }
  }

  if (entityType === 'CONTENT') {
    const contentEntity = thunkAPI.getState().content.entity

    if (contentEntity) {
      // remove related snippet reference

      const referencePath = getReferencePath({
        configPath: targetPath,
        data: contentEntity,
      })

      const updatedReferences = contentEntity?.references?.filter(
        ({ typeTo, pathFrom }) =>
          !(typeTo === 'SNIPPET' && pathFrom === referencePath),
      )

      // update content data to match latest snippet data values
      const updatedContent = changeObjectPropertyByJmespath<ContentType>(
        contentEntity,
        targetPath,
        snippetDataValue,
        {
          shouldMergeValue: snippet?.meta?.shouldMergeData,
        },
      )

      thunkAPI.dispatch(
        updateContentData({
          queryVariables: {
            configPath: '',
            configValue: updatedContent.data,
            referencesToReplace: updatedReferences,
          },
          thunkOptions: {
            shouldReplaceValue: true,
          },
        }),
      )
    }
  }

  if (entityType === 'SNIPPET') {
    const snippetEntity = thunkAPI
      .getState()
      .snippets.entities.find((snippet) => snippet.id === sourceId)

    if (snippetEntity) {
      // remove related snippet reference

      const referencePath = getReferencePath({
        configPath: targetPath,
        data: snippetEntity,
      })

      const updatedReferences = snippetEntity?.references?.filter(
        ({ typeTo, pathFrom }) =>
          !(typeTo === 'SNIPPET' && pathFrom === referencePath),
      )

      // update snippet data to match latest snippet data values
      const updatedSnippet = changeObjectPropertyByJmespath<SnippetType>(
        snippetEntity,
        targetPath,
        snippetDataValue,
        {
          shouldMergeValue: snippet?.meta?.shouldMergeData,
        },
      )

      thunkAPI.dispatch(
        updateSnippet({
          queryVariables: {
            value: updatedSnippet?.data?.value,
            snippetId: snippetEntity.id!,
            referencesToReplace: updatedReferences,
          },
          thunkOptions: {
            shouldReplaceValue: true,
          },
        }),
      )
    }
  }
})

export const discardSnippet = createGraphqlAsyncThunkByDocument<
  'DiscardSnippetMutationDocument',
  'discardSnippet',
  DiscardSnippetQueryVarsType
>()('snippets/snippet/discard', async ({ queryVariables }, thunkAPI) => {
  const { snippetId } = queryVariables

  const snippet = thunkAPI
    .getState()
    .snippets.entities.find((snippet) => snippet.id === snippetId)

  if (!snippet) {
    return thunkReject({
      code: 'data_not_found',
      message: 'Snippet not found',
      thunkAPI,
      rejectQueries: ['SNIPPETS'],
    })
  }

  return await asyncThunkFetcher({
    query: 'DiscardSnippetMutationDocument',
    selector: 'discardSnippet',
    variables: {
      id: snippet.id!,
    },
    thunkAPI,
    rejectQueries: ['SNIPPETS'],
  })
})

export const issueSnippet = createGraphqlAsyncThunkByDocument<
  'IssueSnippetMutationDocument',
  'issueSnippet',
  IssueSnippetQueryVarsType
>()(
  'snippets/snippet/issue',
  async ({ queryVariables }, thunkAPI) => {
    const { snippetId } = queryVariables

    const snippet = thunkAPI
      .getState()
      .snippets.entities.find((snippet) => snippet.id === snippetId)

    if (!snippet) {
      return thunkReject({
        code: 'data_not_found',
        message: 'Snippet not found',
        thunkAPI,
        rejectQueries: ['SNIPPETS'],
      })
    }

    return await asyncThunkFetcher({
      query: 'IssueSnippetMutationDocument',
      selector: 'issueSnippet',
      variables: {
        id: snippet.id!,
      },
      rejectQueries: ['SNIPPETS'],
      thunkAPI,
    })
  },
  {
    getPendingMeta: (base, api) => {
      const snippetId = base?.arg?.queryVariables?.snippetId
      const snippets = api.getState().snippets.entities

      const dependantSnippetsIds = getSnippetDependencyByRefs({
        snippetId,
        snippets,
        filters: ['onlyWithHashDifference'],
      })

      return {
        pendingData: {
          snippetIdsToIssue: [snippetId, ...dependantSnippetsIds],
        },
      }
    },
  },
)

export const assignSnippet = createGraphqlAsyncThunk<
  void,
  AssignSnippetQueryVarsType
>('snippets/snippet/assign', async ({ queryVariables }, thunkAPI) => {
  const { snippetId, targetPath, entityType, entity, basePath } = queryVariables

  const snippet = thunkAPI
    .getState()
    .snippets.entities.find((snippet) => snippet.id === snippetId)

  if (!snippet) {
    return thunkReject({
      code: 'data_not_found',
      message: 'Snippet not found',
      rejectQueries: ['SNIPPETS'],
      thunkAPI,
    })
  }

  const snippetTargetPathWithBasePath = basePath
    ? `${basePath}.${targetPath}`
    : targetPath

  const snippetData = snippet?.data?.value

  const snippetReferenceResolveMethod = snippet?.meta?.shouldMergeData
    ? 'MERGE'
    : 'REPLACE'

  if (entityType === 'CONTENT') {
    const contentEntity = entity as ContentType
    const layoutConfig = contentEntity.data || {}

    const referencePath = getReferencePath({
      configPath: snippetTargetPathWithBasePath,
      data: { config: layoutConfig },
    })

    const updatedReferences = appendSnippetReference({
      references: contentEntity.references!,
      data: {
        id: snippetId,
        path: referencePath,
        resolveMethod: snippetReferenceResolveMethod,
      },
    })

    const dataAlreadyExists = search(
      contentEntity,
      normalizeJMESPath(snippetTargetPathWithBasePath),
    )

    let updatedContentData = contentEntity

    if (dataAlreadyExists) {
      updatedContentData = changeObjectPropertyByJmespath(
        contentEntity,
        snippetTargetPathWithBasePath,
        snippetData,
        {
          shouldMergeValue: snippet?.meta?.shouldMergeData,
        },
      )
    } else {
      updatedContentData = assocJMESPath(
        snippetTargetPathWithBasePath,
        snippetData,
        contentEntity,
      )
    }

    thunkAPI.dispatch(
      updateContentData({
        queryVariables: {
          configPath: `config`,
          configValue: search(updatedContentData, 'data.config'),
          referencesToReplace: updatedReferences,
        },
      }),
    )
  }

  if (entityType === 'CONTENTBLOCK') {
    const blockEntity = entity as BlockType
    const referencePath = getReferencePath({
      configPath: snippetTargetPathWithBasePath,
      data: {
        config: blockEntity.config,
      },
    })

    const updatedReferences = appendSnippetReference({
      references: blockEntity.references,
      data: {
        id: snippetId,
        path: referencePath,
        resolveMethod: snippetReferenceResolveMethod,
      },
    })

    const dataAlreadyExists = search(
      blockEntity,
      normalizeJMESPath(snippetTargetPathWithBasePath),
    )

    let updatedBlockData = blockEntity

    if (dataAlreadyExists) {
      updatedBlockData = changeObjectPropertyByJmespath(
        blockEntity,
        snippetTargetPathWithBasePath,
        snippetData,
        {
          shouldMergeValue: snippet?.meta?.shouldMergeData,
        },
      )
    } else {
      updatedBlockData = assocJMESPath(
        snippetTargetPathWithBasePath,
        snippetData,
        blockEntity,
      )
    }
    // TODO fix typescript
    thunkAPI.dispatch(
      resolveUpdateContentBlock(
        {
          blockId: blockEntity.id,
          configPath: targetPath,
          configValue: search(
            updatedBlockData,
            normalizeJMESPath(snippetTargetPathWithBasePath),
          ),
          referencesToReplace: updatedReferences,
        },
        {
          shouldReplaceValue: true,
        },
      ),
    )
  }

  if (entityType === 'SNIPPET') {
    const snippetEntity = entity as SnippetType
    const referencePath = getReferencePath({
      configPath: snippetTargetPathWithBasePath,
      data: snippetEntity,
    })

    const updatedReferences = appendSnippetReference({
      references: snippetEntity.references,
      data: {
        id: snippetId,
        path: referencePath,
        resolveMethod: snippetReferenceResolveMethod,
      },
    })

    const dataAlreadyExists = search(
      snippetEntity,
      normalizeJMESPath(snippetTargetPathWithBasePath),
    )

    let updatedSnippetData = snippetEntity

    if (dataAlreadyExists) {
      updatedSnippetData = changeObjectPropertyByJmespath(
        snippetEntity,
        snippetTargetPathWithBasePath,
        snippetData,
        {
          shouldMergeValue: snippet?.meta?.shouldMergeData,
        },
      )
    } else {
      updatedSnippetData = assocJMESPath(
        snippetTargetPathWithBasePath,
        snippetData,
        snippetEntity,
      )
    }

    thunkAPI.dispatch(
      updateSnippet({
        queryVariables: {
          value: updatedSnippetData?.data?.value,
          snippetId: snippetEntity.id!,
          referencesToReplace: updatedReferences,
        },
        thunkOptions: {
          shouldReplaceValue: true,
        },
      }),
    )
  }
})

export const createSnippetWithCid =
  createAppendCidThunk<typeof createSnippet>(createSnippet)

export const createSnippetRawWithCid =
  createAppendCidThunk<typeof createSnippetRaw>(createSnippetRaw)

// ---------------
// Reducer
// ---------------

export const snippetsSlice = createSlice({
  name: 'snippets',
  initialState,
  reducers: {
    resetSnippetsState: () => {
      return initialState
    },
    wsCreateSnippet: (state, action: PayloadAction<SnippetType>) => {
      const actionPayload = action.payload as SnippetType
      const existingSnippetIndex = state.entities.findIndex(
        ({ cid }) => cid === actionPayload.cid,
      )

      if (existingSnippetIndex > -1) {
        // the same comment already exists
        const existingSnippet = state.entities[existingSnippetIndex]
        const isPending = existingSnippet.cid === existingSnippet.cid
        if (isPending) {
          state.entities[existingSnippetIndex] = actionPayload
        }
      } else {
        state.entities = [...state.entities, actionPayload]
      }
    },
    wsDeleteSnippet: (state, action: PayloadAction<SnippetType>) => {
      const { id } = action.payload as SnippetType
      state.entities = state.entities.filter((snippet) => snippet.id !== id)
    },
    wsUpdateSnippet: (state, action: PayloadAction<SnippetType>) => {
      const snippetId = action.payload.id

      const existingSnippetIndex = state.entities.findIndex(
        ({ id }) => id === snippetId,
      )

      if (existingSnippetIndex > -1) {
        state.entities[existingSnippetIndex] = action.payload
      }
    },
    wsDiscardSnippet: (state, action: PayloadAction<SnippetType>) => {
      const snippetId = action.payload.id

      const existingSnippetIndex = state.entities.findIndex(
        ({ id }) => id === snippetId,
      )

      if (existingSnippetIndex > -1) {
        state.entities[existingSnippetIndex] = action.payload
      }
    },
    wsIssuedSnippet: (state, action: PayloadAction<SnippetType[]>) => {
      const issuedSnippets = action.payload

      issuedSnippets.forEach((issuedSnippet) => {
        const snippetIndex = state.entities.findIndex(
          (snippet) => snippet.id === issuedSnippet.id,
        )

        if (snippetIndex > -1) {
          state.entities[snippetIndex] = issuedSnippet
        }
      })
    },
  },
  extraReducers(builder) {
    builder
      .addCase('USER:LOGOUT', () => {
        return initialState
      })
      .addCase(fetchSnippets.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchSnippets.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const payload = action.payload
        state.entities = payload || []
      })
      .addCase(fetchSnippets.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.code || null
      })
      .addCase(createSnippet.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const { cid, snippet } = action.meta.arg.queryVariables

          if (!cid) {
            return
          }

          const { isValid } = validateSnippet(snippet)

          if (!isValid) {
            return
          }

          state.entities = [
            ...state.entities,
            {
              ...snippet,
              id: cid,
              cid,
            },
          ]
        }
      })
      .addCase(createSnippet.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          // replace pending snippet data with api data
          const { cid } = action.payload
          const pendingSnippetIndex = state.entities.findIndex(
            (snippet) => snippet.cid === cid,
          )

          state.entities[pendingSnippetIndex] = action.payload
        }
      })
      .addCase(createSnippetRaw.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const snippet = action.meta.arg.queryVariables

          if (!snippet.cid) {
            return
          }

          const { isValid } = validateSnippet(snippet)

          if (!isValid) {
            return
          }

          state.entities = [
            ...state.entities,
            {
              ...snippet,
              id: snippet.cid,
              cid: snippet.cid,
            },
          ]
        }
      })
      .addCase(createSnippetRaw.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          // replace pending snippet data with api data
          const { cid } = action.payload
          const pendingSnippetIndex = state.entities.findIndex(
            (snippet) => snippet.cid === cid,
          )

          state.entities[pendingSnippetIndex] = action.payload
        }
      })
      .addCase(updateSnippet.pending, (state, action) => {
        const { queryVariables, thunkOptions } = action.meta.arg

        //prevent old requests overwriting the current one
        state.requests.updateSnippetRequestId = action.meta.requestId

        const { snippetId, value } = queryVariables

        const snippetIndex = state.entities.findIndex(
          (snippet) => snippet.id === snippetId,
        )
        let snippet = state.entities[snippetIndex]

        let snippetData = snippet.data || {}
        if (value !== undefined) {
          if (thunkOptions?.shouldReplaceValue) {
            snippetData = assocJMESPath('value', value, snippetData) as Record<
              string,
              any
            >
          } else {
            snippetData = flattenUpdate(snippetData, { value: value }, '')
          }

          if (thunkOptions?.shouldRemoveEmptyValues) {
            snippetData = assocJMESPath(
              'value',
              removeEmptyValues(search(snippetData, 'value')),
              snippetData,
            )
          }
        }

        const { isValid } = validateSnippet({
          ...snippet,
          data: snippetData,
        })

        if (!isValid) {
          return
        }

        const { references: snippetReferences } = snippet

        // final references cleanup
        const updatedReferences = referencesCleanup({
          references: snippetReferences,
          data: { data: snippetData },
        })

        state.entities[snippetIndex] = {
          ...snippet,
          data: snippetData,
          references: updatedReferences,
          ...(value !== undefined && { hash: randomString(10) }),
        }
      })
      .addCase(updateSnippet.fulfilled, (state, action) => {
        const { snippetId } = action.meta.arg.queryVariables

        //prevent old requests overwriting the current one
        if (action.meta.requestId !== state.requests.updateSnippetRequestId) {
          return
        }

        const snippetIndex = state.entities.findIndex(
          (snippet) => snippet.id === snippetId,
        )

        state.entities[snippetIndex] = action.payload
      })
      .addCase(discardSnippet.pending, (state, action) => {
        const { snippetId } = action.meta.arg.queryVariables

        const snippetIndex = state.entities.findIndex(
          (snippet) => snippet.id === snippetId,
        )

        if (snippetIndex > -1) {
          const snippet = state.entities[snippetIndex]

          const issuedSnippet = snippet.issued

          state.entities[snippetIndex] = {
            ...snippet,
            data: issuedSnippet?.data,
          }
        }
      })
      .addCase(discardSnippet.fulfilled, (state, action) => {
        const { snippetId } = action.meta.arg.queryVariables

        const snippetIndex = state.entities.findIndex(
          (snippet) => snippet.id === snippetId,
        )

        state.entities[snippetIndex] = action.payload
      })
      .addCase(issueSnippet.pending, (state, action) => {
        const { snippetIdsToIssue } = action.meta.pendingData

        snippetIdsToIssue.forEach((id) => {
          const snippetIndex = state.entities.findIndex(
            (snippet) => snippet.id === id,
          )

          if (snippetIndex > -1) {
            const snippet = state.entities[snippetIndex]

            const issuedSnippet = snippet.issued!

            state.entities[snippetIndex] = {
              ...snippet,
              issued: {
                ...issuedSnippet,
                hash: snippet?.hash!,
              },
            }
          }
        })
      })
      .addCase(issueSnippet.fulfilled, (state, action) => {
        const issuedSnippets = action.payload

        issuedSnippets.forEach((issuedSnippet) => {
          const snippetIndex = state.entities.findIndex(
            (snippet) => snippet.id === issuedSnippet.id,
          )

          if (snippetIndex > -1) {
            state.entities[snippetIndex] = issuedSnippet
          }
        })
      })
      .addCase(deleteSnippet.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const { id } = action.meta.arg.queryVariables

          state.entities = state.entities.filter((snippet) => snippet.id !== id)
        }
      })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useSnippetsDispatch: () => AppDispatch = useDispatch
export const useSnippetsSelector: TypedUseSelectorHook<RootState> = useSelector

// Action creators are generated for each case reducer function
export const {
  resetSnippetsState,
  wsDiscardSnippet,
  wsCreateSnippet,
  wsUpdateSnippet,
  wsDeleteSnippet,
  wsIssuedSnippet,
} = snippetsSlice.actions

export default snippetsSlice.reducer
