import { ButtonComponent, ButtonsBlock } from '@sceneio/schemas/lib/types'
import { randomString } from '@sceneio/tools'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { blockContainerDefaults } from '../../components/BlockContainer/blockContainerMeta'
import { BlockComponentMeta, BlockMeta } from '../blockCommonTypes'
import { blockDefaultConfigFactory } from '../helpers'

import { containerComponentFormRegisterValuesFactory } from '../../components/Container/form/ContainerComponentForm'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from './form/additionalFormFieldRenderers'

const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

export const buttonSampleFactory = (data = {}): ButtonComponent => ({
  id: randomString(8),
  children: textToLexical('Button', 'array-item'),
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
  ...data,
})

export const buttonsComponentMeta: BlockComponentMeta<ButtonsBlock> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      responsiveConfig: {
        default: {
          maxWidth: 'screen',
          rowGap: '1.5rem',
          columnGap: '1.5rem',
        },
      },
      padding: BLOCK_CONTAINER_DEFAULTS.padding,
      direction: 'horizontal',
    },
    type: 'blockContainer',
  },
  buttons: {
    path: 'components.buttons',
    components: {
      items: {
        isArrayComponent: true,
        path: 'items',
        name: 'Button',
        type: 'button',
        disableToggleShow: true,
        sampleFactory: () => buttonSampleFactory(),
      },
    },
    inlineEditors: [
      {
        editorType: 'buttonEditor',
        editorPath: 'components.buttons.items',
        editorOptions: {
          isItemArray: true,
          sampleFactory: () => buttonSampleFactory(),
        },
      },
    ],
    defaultConfig: {
      items: [buttonSampleFactory()],
      show: true,
      gap: {
        data: {
          [`--row-gap-default`]: '1rem',
          [`--column-gap-default`]: '1rem',
        },
      },
      flow: {
        data: {
          '--flow-default': 'row',
          '--flow-xs': 'column',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
    },
    name: 'Content',
    type: 'container',
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
        },
      },
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
}

export const buttonsBlockMeta: BlockMeta<ButtonsBlock> = {}

export const buttonsDefaultConfig = blockDefaultConfigFactory<ButtonsBlock>({
  componentMeta: buttonsComponentMeta,
  blockMeta: buttonsBlockMeta,
  version: 1,
})
