import { BreakpointType } from '../BreakpointDropdown/BreakpointDropdown'
import { StateType } from '../StateTabs/StateTabs'

export const isSingleValueRadius = ({
  values = {},
  valuesPath,
  state,
  breakpoint,
}: {
  values: Record<string, any>
  valuesPath: string
  state: StateType
  breakpoint: BreakpointType | 'default'
}) => {
  const radiusTopRight =
    values[
      `${valuesPath}.${state}.--border-${breakpoint}-radius-top-right-${state}`
    ]
  const radiusTopLeft =
    values[
      `${valuesPath}.${state}.--border-${breakpoint}-radius-top-left-${state}`
    ]
  const radiusBottomRight =
    values[
      `${valuesPath}.${state}.--border-${breakpoint}-radius-bottom-right-${state}`
    ]
  const radiusBottomLeft =
    values[
      `${valuesPath}.${state}.--border-${breakpoint}-radius-bottom-left-${state}`
    ]

  const result = [radiusTopRight, radiusTopLeft, radiusBottomLeft].every(
    (value) => {
      return value === radiusBottomRight
    },
  )

  return result
}
