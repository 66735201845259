import { createSelector, createSlice } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { EditorSliceType } from '../editor/editorSliceTypes'
import type { PayloadAction } from '@reduxjs/toolkit'

export type PersistSliceType = {
  showAINotebook: boolean
}

// ---------------
// Initial State
// ---------------
export const initialState: PersistSliceType = {
  showAINotebook: false,
} as PersistSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

// ---------------
// Reducer
// ---------------

export const persistSlice = createSlice({
  name: 'persist',
  initialState: () => initialState,
  reducers: {
    setShowAINotebook: (
      state,
      action: PayloadAction<PersistSliceType['showAINotebook']>,
    ) => {
      state.showAINotebook = action.payload
    },
    toggleShowAINotebook: (state) => {
      state.showAINotebook = !state.showAINotebook
    },
  },
  extraReducers: (builder) => {},
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const usePersistDispatch: () => AppDispatch = useDispatch
export const usePersistSelector: TypedUseSelectorHook<RootState> = useSelector

// Action creators are generated for each case reducer function
export const { setShowAINotebook, toggleShowAINotebook } = persistSlice.actions

export const selectShowAINotebook = (state: RootState) => {
  return state.persist.showAINotebook
}

export default persistSlice.reducer
