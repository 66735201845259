import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'

export const accordionComponentMeta: ComponentMeta<'accordion'> = {
  defaultConfig: {
    gap: {
      data: {
        '--row-gap-default': '1rem',
      },
    },
  },
}

export const accordionDefaults = componentDefaultConfigFactory<'accordion'>({
  componentMeta: accordionComponentMeta,
})
