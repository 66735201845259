import { dissocPath, curry } from 'ramda'
import { convertJMESPathToArrayPath } from './convertJMESPathToArrayPath'

export function dissocJMESPath(
  path?: string,
  object?: Record<string, any>,
): Record<string, any> {
  if (!path) throw Error('Property `path` is not defined')
  if (!object) throw Error('Property `object` is not defined')
  const normalizedPath = convertJMESPathToArrayPath(path)
  return dissocPath(normalizedPath, object)
}
export const curriedDissocJMESPath = curry(dissocJMESPath)
