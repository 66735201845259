import { normalizeJMESPath } from '@sceneio/tools'
import { search } from 'jmespath'

const nestedArrayPathRegex = new RegExp(/^(.?)+(\[\d+\])/)

export function getReferencePath({
  configPath,
  data,
}: {
  configPath: string
  data: Record<string, any>
}): string {
  if (!configPath) throw Error('Property `configPath` is not defined')
  if (!data) throw Error('Property `data` is not defined')

  const normalizedConfigPath = configPath.replaceAll(/\.(\d+)/g, '[$1]')
  const isNestedArrayPath = nestedArrayPathRegex.test(normalizedConfigPath)

  if (isNestedArrayPath) {
    return normalizedConfigPath
      .split('.')
      .reduce((acc, val) => {
        if (nestedArrayPathRegex.test(val)) {
          const id = search(data, `${[...acc, val].join('.')}.id`)
          if (!id) {
            throw Error(
              'Property `id` required for each array item is not defined',
            )
          }
          acc.push(val.replace(/(\[\d+])/g, `[?id=='${id}']|[0]`))
        } else {
          acc.push(val)
        }
        return acc
      }, [])
      .join('.')
  }

  return configPath
}
