import { randomString } from '@sceneio/tools'
import { imageDefaults } from '../../../components/Image/imageMeta'
import { blockLayoutAdditionalFormFieldRenderer } from '../../Hero/SplitHero/form/additionalFormFieldRenderers'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import type { BlockComponent, LogoStripBlock } from '@sceneio/schemas/lib/types'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'
import { textToLexical } from '@sceneio/wysiwyg-utils'

const IMAGE_DEFAULTS = imageDefaults.config
const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

export const logoImageSampleFactory = () => ({
  ...IMAGE_DEFAULTS,
  responsiveConfig: {
    default: {
      alt: 'This is image alt',
      src: 'https://assets.scene.io/global/placeholders/scene-logo-placeholder.svg',
      width: 150,
    },
  },
  id: randomString(8),
})

export const logoStripComponentMeta: BlockComponentMeta<LogoStripBlock> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      swapOrder: false,
      columnRatio: 4,
    } as BlockComponent & {
      swapOrder?: boolean
      columnRatio: number
    },
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: blockLayoutAdditionalFormFieldRenderer,
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      responsiveConfig: {
        default: {
          maxWidth: 'screen',
          columnGap: '3rem',
          rowGap: '2.5rem',
        },
      },
      padding: BLOCK_CONTAINER_DEFAULTS.padding,
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
    },
    type: 'blockContainer',
  },
  heading: {
    path: 'components.heading',
    name: 'Heading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.heading',
      },
    ],
    defaultConfig: {
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h3'],
      children: textToLexical('Trusted by some awesome companies', 'h3'),
      as: 'h3',
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-xs': 'center',
        },
      },
      show: true,
    },
    disableToggleShow: true,
    type: 'text',
  },
  logoImages: {
    path: 'components.logoImages',
    components: {
      items: {
        isArrayComponent: true,
        path: 'items',
        name: 'Logo',
        type: 'image',
        disableToggleShow: true,
        sampleFactory: logoImageSampleFactory,
      },
    },
    defaultConfig: {
      items: [
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
      ],
      show: true,
      gap: {
        data: {
          [`--row-gap-default`]: '2.5rem',
          [`--column-gap-default`]: '2.5rem',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'flex-end',
          '--horizontal-alignment-xs': 'center',
        },
      },
      columns: {
        data: {
          '--columns-default': 4,
          '--columns-md': 4,
          '--columns-xs': 2,
        },
      },
    },
    disableToggleShow: true,
    name: 'Logos',
    type: 'grid',
    configEditor: {
      formProps: {
        cssType: 'flex',
      },
    },
  },
}

export const logoStripBlockMeta: BlockMeta<LogoStripBlock> = {}

export const logoStripDefaultConfig = blockDefaultConfigFactory<LogoStripBlock>(
  {
    componentMeta: logoStripComponentMeta,
    blockMeta: logoStripBlockMeta,
    version: 1,
  },
)
