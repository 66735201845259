import { normalizeJMESPath } from '@sceneio/tools/lib/normalizeJMESPath'
import {
  ReferencesObjectType,
  transformReferencesFromArrayToObject,
} from './helpers/transformReferencesFromArrayToObject'
import { transformReferencesFromObjectToArray } from './helpers/transformReferencesFromObjectToArray'
import { ReferencesType, ReferenceType } from './referencesTypes'
import { search } from 'jmespath'

export type ReferencesCleanupType = {
  references: ReferencesType
  data: Record<string, any>
  targetPath?: string
}

function differentiateReferences({
  referencesObject,
  targetPath,
}: {
  targetPath?: string
  referencesObject: ReferencesObjectType
}): {
  relatedReferencesObject: ReferencesObjectType
  unrelatedReferencesObject: ReferencesObjectType
} {
  let relatedReferencesObject = {}
  let unrelatedReferencesObject = {}

  if (!targetPath) {
    return {
      relatedReferencesObject: referencesObject,
      unrelatedReferencesObject,
    }
  }

  Object.keys(referencesObject).forEach((referencePath) => {
    if (referencePath.startsWith(targetPath)) {
      relatedReferencesObject[referencePath] = referencesObject[referencePath]
    } else {
      unrelatedReferencesObject[referencePath] = referencesObject[referencePath]
    }
  })

  return {
    relatedReferencesObject,
    unrelatedReferencesObject,
  }
}

export function referencesCleanup({
  references = [],
  data,
  targetPath,
}: ReferencesCleanupType): ReferencesType {
  const referencesObject = transformReferencesFromArrayToObject(references)
  const result = {}

  // differentiate related & unrelated references according to optional targetPath
  const { relatedReferencesObject, unrelatedReferencesObject } =
    differentiateReferences({ referencesObject: referencesObject, targetPath })

  for (let referencePath in relatedReferencesObject) {
    const normalizedJMESReferencePath = normalizeJMESPath(referencePath)

    const isReferenceExist = search(data, normalizedJMESReferencePath)

    if (isReferenceExist) {
      result[referencePath] = relatedReferencesObject[referencePath]
    }
  }

  return transformReferencesFromObjectToArray({
    ...unrelatedReferencesObject,
    ...result,
  })
}
