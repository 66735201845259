import { randomString } from '@sceneio/tools'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import type {
  BlockComponent,
  GalleryGridBlock,
} from '@sceneio/schemas/lib/types'
import { galleryItemDefaults } from '../../../components/GalleryItem/galleryItemMeta'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { blockLayoutAdditionalFormFieldRenderer } from '../GalleryGrid/form/additionalFormFieldRenderers'

const GALLERY_ITEM_DEFAULTS = galleryItemDefaults.config
const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

const galleryItemSampleFactory = (src?: string) => ({
  ...GALLERY_ITEM_DEFAULTS,
  id: randomString(8),
  components: {
    ...GALLERY_ITEM_DEFAULTS.components,
    image: {
      transformationOptions: { cover: '100px100p' },
      responsiveConfig: {
        default: {
          src:
            src ||
            'https://assets.scene.io/global/placeholders/media-1200x900.png',
        },
      },
    },
  },
})

export const masonryGallery2ComponentMeta: BlockComponentMeta<GalleryGridBlock> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        enableLightbox: true,
      } as BlockComponent & {
        enableLightbox?: boolean
      },
      configEditor: {
        additionalFormFields: {
          layout: {
            formFieldRenderer: blockLayoutAdditionalFormFieldRenderer,
          },
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        responsiveConfig: {
          default: {
            maxWidth: 'screen',
            rowGap: '1.5rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: BLOCK_CONTAINER_DEFAULTS.padding,
      },
      type: 'blockContainer',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
        children: textToLexical('Image Gallery', 'h2'),
        as: 'h2',
        padding: {
          data: {
            '--padding-default-left': '20%',
            '--padding-default-right': '20%',
            '--padding-xs-left': '0%',
            '--padding-xs-right': '0%',
            '--padding-md-left': '10%',
            '--padding-md-right': '10%',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        show: true,
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        ),
        as: 'p',
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: {
          data: {
            '--padding-default-left': '20%',
            '--padding-default-right': '20%',
            '--padding-xs-left': '0%',
            '--padding-xs-right': '0%',
            '--padding-md-left': '10%',
            '--padding-md-right': '10%',
          },
        },
        show: true,
      },
      type: 'text',
    },
    galleryItems: {
      path: 'components.galleryItems',
      name: 'Gallery Items',
      defaultConfig: {
        items: [
          galleryItemSampleFactory(),
          galleryItemSampleFactory(
            'https://assets.scene.io/global/placeholders/media-720x900.png',
          ),
          galleryItemSampleFactory(
            'https://assets.scene.io/global/placeholders/media-720x900.png',
          ),
          galleryItemSampleFactory(
            'https://assets.scene.io/global/placeholders/media-720x900.png',
          ),
          galleryItemSampleFactory(),
          galleryItemSampleFactory(
            'https://assets.scene.io/global/placeholders/media-720x900.png',
          ),
        ],
        gap: {
          data: {
            '--row-gap-default': '1.5rem',
            '--column-gap-default': '1.5rem',
          },
        },
        columns: {
          data: {
            '--columns-default': 3,
            '--columns-xs': 1,
          },
        },
        margin: {
          data: {
            '--margin-default-top': '2.5rem',
          },
        },
      },
      components: {
        items: {
          name: 'Gallery Item',
          isArrayComponent: true,
          path: 'items',
          type: 'galleryItem',
          disableToggleShow: true,
          sampleFactory: galleryItemSampleFactory,
          components: {
            image: {
              name: 'Image',
              path: 'components.image',
              type: 'galleryImage',
            },
            heading: {
              type: 'text',
              name: 'Heading',
              path: 'components.heading',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.heading',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            description: {
              type: 'text',
              name: 'Description',
              path: 'components.description',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.description',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
          },
        },
      },
      disableToggleShow: true,
      type: 'masonryGrid',
    },
  }

export const masonryGallery2BlockMeta: BlockMeta<GalleryGridBlock> = {}

export const masonryGallery2DefaultConfig =
  blockDefaultConfigFactory<GalleryGridBlock>({
    componentMeta: masonryGallery2ComponentMeta,
    blockMeta: masonryGallery2BlockMeta,
    version: 1,
  })
