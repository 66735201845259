import { InferType, bool, number, object, string } from 'yup'
import { styleSchema } from '../shared/styleSchema'
import { cssLengthUnitSchema } from '../styles/common/cssLengthUnitSchema'
import { textComponentSchema } from './textComponentSchema'

const videoResponsiveConfigBreakpointSchema = object({
  src: string().required(),
  posterImageSrc: string(),
  posterImageAlt: string(),
  posterImageClasses: string(),
  posterImageHeight: number(),
  posterImageWidth: number(),
  posterImageId: string(),
  posterVideoSrc: string(),
  posterType: string().oneOf(['image', 'video']),
}).noUnknown()

export const videoComponentSchema = object({
  className: string(),
  isBackgroundVideo: bool(),
  showPlayButton: bool(),
  showControls: bool(),
  showPoster: bool(),
  usePlayer: bool(),
  playButton: object({
    data: object({
      default: object({
        '--play-button-default-color-default': string(),
        '--play-button-default-size-default': cssLengthUnitSchema,
        '--play-button-md-size-default': cssLengthUnitSchema,
        '--play-button-lg-size-default': cssLengthUnitSchema,
        '--play-button-default-background-default': string(),
      })
        .noUnknown()
        .optional(),
      hover: object({
        '--play-button-default-color-hover': string(),
        '--play-button-default-size-hover': cssLengthUnitSchema,
        '--play-button-md-size-hover': cssLengthUnitSchema,
        '--play-button-lg-size-hover': cssLengthUnitSchema,
        '--play-button-default-background-hover': string(),
      })
        .noUnknown()
        .optional(),
    })
      .noUnknown()
      .optional(),
  })
    .noUnknown()
    .optional(),
  duration: textComponentSchema.optional(),
  format: string().oneOf(['mp4', 'webm', 'ogg']),
  language: string(),
  show: bool(),
  cover: bool(),
  style: styleSchema,
  responsiveConfig: object({
    default: videoResponsiveConfigBreakpointSchema.optional(),
    xs: videoResponsiveConfigBreakpointSchema.optional(),
    md: videoResponsiveConfigBreakpointSchema.optional(),
    lg: videoResponsiveConfigBreakpointSchema.optional(),
  })
    .noUnknown()
    .optional(),
  preview: bool(),
}).noUnknown()

export type VideoComponent = InferType<typeof videoComponentSchema>
