import { InferType, lazy, number, object, string } from 'yup'
import { cssFontSizeUnitSchema } from './common/cssFontSizeUnitSchema'
import { cssLengthUnitSchema } from './common/cssLengthUnitSchema'
import { cssTextDecorationStyleSchema } from './common/cssTextDecorationStyleSchema'
import { cssFontWeightSchema } from './common/cssFontWeightSchema'
import { cssTextDecorationLineSchema } from './common/cssTextDecorationLineSchema'

const fontFamilySchema = string()

export const fontTextStyleSchema = string().oneOf([
  'inherit',
  'normal',
  'oblique',
  'italic',
])

const fontLineHeightSchema = lazy((value) => {
  if (typeof value === 'number') {
    return number().min(0)
  }
  return cssFontSizeUnitSchema
})

const fontTextTransformSchema = string().oneOf([
  'none',
  'capitalize',
  'uppercase',
  'lowercase',
])

export const fontStyleDataSchema = object({
  default: object({
    '--font-default-size-default': cssFontSizeUnitSchema,
    '--font-default-family-default': fontFamilySchema,
    '--font-default-style-default': fontTextStyleSchema,
    '--font-default-weight-default': cssFontWeightSchema,
    '--font-default-line-height-default': fontLineHeightSchema,
    '--font-default-word-spacing-default': cssLengthUnitSchema,
    '--font-default-letter-spacing-default': cssLengthUnitSchema,
    '--font-default-text-transform-default': fontTextTransformSchema,
    '--font-default-text-decoration-color-default': string(),
    '--font-default-text-decoration-style-default':
      cssTextDecorationStyleSchema,
    '--font-default-text-decoration-line-default': cssTextDecorationLineSchema,
    '--font-xs-size-default': cssFontSizeUnitSchema,
    '--font-xs-family-default': fontFamilySchema,
    '--font-xs-style-default': fontTextStyleSchema,
    '--font-xs-weight-default': cssFontWeightSchema,
    '--font-xs-line-height-default': fontLineHeightSchema,
    '--font-xs-word-spacing-default': cssLengthUnitSchema,
    '--font-xs-letter-spacing-default': cssLengthUnitSchema,
    '--font-xs-text-transform-default': fontTextTransformSchema,
    '--font-xs-text-decoration-color-default': string(),
    '--font-xs-text-decoration-style-default': cssTextDecorationStyleSchema,
    '--font-xs-text-decoration-line-default': cssTextDecorationLineSchema,
    '--font-md-size-default': cssFontSizeUnitSchema,
    '--font-md-family-default': fontFamilySchema,
    '--font-md-style-default': fontTextStyleSchema,
    '--font-md-weight-default': cssFontWeightSchema,
    '--font-md-line-height-default': fontLineHeightSchema,
    '--font-md-word-spacing-default': cssLengthUnitSchema,
    '--font-md-letter-spacing-default': cssLengthUnitSchema,
    '--font-md-text-transform-default': fontTextTransformSchema,
    '--font-md-text-decoration-color-default': string(),
    '--font-md-text-decoration-style-default': cssTextDecorationStyleSchema,
    '--font-md-text-decoration-line-default': cssTextDecorationLineSchema,
    '--font-lg-size-default': cssFontSizeUnitSchema,
    '--font-lg-family-default': fontFamilySchema,
    '--font-lg-style-default': fontTextStyleSchema,
    '--font-lg-weight-default': cssFontWeightSchema,
    '--font-lg-line-height-default': fontLineHeightSchema,
    '--font-lg-word-spacing-default': cssLengthUnitSchema,
    '--font-lg-letter-spacing-default': cssLengthUnitSchema,
    '--font-lg-text-transform-default': fontTextTransformSchema,
    '--font-lg-text-decoration-color-default': string(),
    '--font-lg-text-decoration-style-default': cssTextDecorationStyleSchema,
    '--font-lg-text-decoration-line-default': cssTextDecorationLineSchema,
  })
    .noUnknown()
    .optional(),
  hover: object({
    '--font-default-size-hover': cssFontSizeUnitSchema,
    '--font-default-family-hover': fontFamilySchema,
    '--font-default-style-hover': fontTextStyleSchema,
    '--font-default-weight-hover': cssFontWeightSchema,
    '--font-default-line-height-hover': fontLineHeightSchema,
    '--font-default-word-spacing-hover': cssLengthUnitSchema,
    '--font-default-letter-spacing-hover': cssLengthUnitSchema,
    '--font-default-text-transform-hover': fontTextTransformSchema,
    '--font-default-text-decoration-color-hover': string(),
    '--font-default-text-decoration-style-hover': cssTextDecorationStyleSchema,
    '--font-default-text-decoration-line-hover': cssTextDecorationLineSchema,
    '--font-xs-size-hover': cssFontSizeUnitSchema,
    '--font-xs-family-hover': fontFamilySchema,
    '--font-xs-style-hover': fontTextStyleSchema,
    '--font-xs-weight-hover': cssFontWeightSchema,
    '--font-xs-line-height-hover': fontLineHeightSchema,
    '--font-xs-word-spacing-hover': cssLengthUnitSchema,
    '--font-xs-letter-spacing-hover': cssLengthUnitSchema,
    '--font-xs-text-transform-hover': fontTextTransformSchema,
    '--font-xs-text-decoration-color-hover': string(),
    '--font-xs-text-decoration-style-hover': cssTextDecorationStyleSchema,
    '--font-xs-text-decoration-line-hover': cssTextDecorationLineSchema,
    '--font-md-size-hover': cssFontSizeUnitSchema,
    '--font-md-family-hover': fontFamilySchema,
    '--font-md-style-hover': fontTextStyleSchema,
    '--font-md-weight-hover': cssFontWeightSchema,
    '--font-md-line-height-hover': fontLineHeightSchema,
    '--font-md-word-spacing-hover': cssLengthUnitSchema,
    '--font-md-letter-spacing-hover': cssLengthUnitSchema,
    '--font-md-text-transform-hover': fontTextTransformSchema,
    '--font-md-text-decoration-color-hover': string(),
    '--font-md-text-decoration-style-hover': cssTextDecorationStyleSchema,
    '--font-md-text-decoration-line-hover': cssTextDecorationLineSchema,
    '--font-lg-size-hover': cssFontSizeUnitSchema,
    '--font-lg-family-hover': fontFamilySchema,
    '--font-lg-style-hover': fontTextStyleSchema,
    '--font-lg-weight-hover': cssFontWeightSchema,
    '--font-lg-line-height-hover': fontLineHeightSchema,
    '--font-lg-word-spacing-hover': cssLengthUnitSchema,
    '--font-lg-letter-spacing-hover': cssLengthUnitSchema,
    '--font-lg-text-transform-hover': fontTextTransformSchema,
    '--font-lg-text-decoration-color-hover': string(),
    '--font-lg-text-decoration-style-hover': cssTextDecorationStyleSchema,
    '--font-lg-text-decoration-line-hover': cssTextDecorationLineSchema,
  })
    .noUnknown()
    .optional(),
  active: object({
    '--font-default-size-active': cssFontSizeUnitSchema,
    '--font-default-family-active': fontFamilySchema,
    '--font-default-style-active': fontTextStyleSchema,
    '--font-default-weight-active': cssFontWeightSchema,
    '--font-default-line-height-active': fontLineHeightSchema,
    '--font-default-word-spacing-active': cssLengthUnitSchema,
    '--font-default-letter-spacing-active': cssLengthUnitSchema,
    '--font-default-text-transform-active': fontTextTransformSchema,
    '--font-default-text-decoration-color-active': string(),
    '--font-default-text-decoration-style-active': cssTextDecorationStyleSchema,
    '--font-default-text-decoration-line-active': cssTextDecorationLineSchema,
    '--font-xs-size-active': cssFontSizeUnitSchema,
    '--font-xs-family-active': fontFamilySchema,
    '--font-xs-style-active': fontTextStyleSchema,
    '--font-xs-weight-active': cssFontWeightSchema,
    '--font-xs-line-height-active': fontLineHeightSchema,
    '--font-xs-word-spacing-active': cssLengthUnitSchema,
    '--font-xs-letter-spacing-active': cssLengthUnitSchema,
    '--font-xs-text-transform-active': fontTextTransformSchema,
    '--font-xs-text-decoration-color-active': string(),
    '--font-xs-text-decoration-style-active': cssTextDecorationStyleSchema,
    '--font-xs-text-decoration-line-active': cssTextDecorationLineSchema,
    '--font-md-size-active': cssFontSizeUnitSchema,
    '--font-md-family-active': fontFamilySchema,
    '--font-md-style-active': fontTextStyleSchema,
    '--font-md-weight-active': cssFontWeightSchema,
    '--font-md-line-height-active': fontLineHeightSchema,
    '--font-md-word-spacing-active': cssLengthUnitSchema,
    '--font-md-letter-spacing-active': cssLengthUnitSchema,
    '--font-md-text-transform-active': fontTextTransformSchema,
    '--font-md-text-decoration-color-active': string(),
    '--font-md-text-decoration-style-active': cssTextDecorationStyleSchema,
    '--font-md-text-decoration-line-active': cssTextDecorationLineSchema,
    '--font-lg-size-active': cssFontSizeUnitSchema,
    '--font-lg-family-active': fontFamilySchema,
    '--font-lg-style-active': fontTextStyleSchema,
    '--font-lg-weight-active': cssFontWeightSchema,
    '--font-lg-line-height-active': fontLineHeightSchema,
    '--font-lg-word-spacing-active': cssLengthUnitSchema,
    '--font-lg-letter-spacing-active': cssLengthUnitSchema,
    '--font-lg-text-transform-active': fontTextTransformSchema,
    '--font-lg-text-decoration-color-active': string(),
    '--font-lg-text-decoration-style-active': cssTextDecorationStyleSchema,
    '--font-lg-text-decoration-line-active': cssTextDecorationLineSchema,
  })
    .noUnknown()
    .optional(),
}).noUnknown()

export const fontStyleSchema = object({
  data: fontStyleDataSchema,
}).noUnknown()

export type FontStyle = InferType<typeof fontStyleSchema>
