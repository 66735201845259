import { InferType, bool, object, string } from 'yup'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { styleSchema } from '../shared/styleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { containerComponentSchema } from './containerComponentSchema'
import { textComponentSchema } from './textComponentSchema'
import { imageComponentSchema } from './imageComponentSchema'
import { thicknessStyleSchema } from '../styles/thicknessStyleSchema'
import { colorStyleSchema } from '../styles/colorStyleSchema'

export const timelineItemComponentSchema = object({
  components: object({
    contentContainer: containerComponentSchema,
    heading: textComponentSchema,
    text: textComponentSchema,
    image: imageComponentSchema,
  }).noUnknown(),
  swapOrder: bool(),
  className: string(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  lineThickness: thicknessStyleSchema.optional(),
  lineColor: colorStyleSchema.optional(),
  show: bool(),
  style: styleSchema,
  editor: componentEditorSchema,
  cid: string(),
}).noUnknown()

export type TimelineItemComponent = InferType<
  typeof timelineItemComponentSchema
>

export type TimelineItemComponentStyles = Pick<
  TimelineItemComponent,
  'margin' | 'padding' | 'lineColor' | 'lineThickness'
>
