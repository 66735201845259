import { RootState } from '../../store'
import { memoize } from 'proxy-memoize'

export const selectAllSnippets = memoize((state: RootState) => {
  const snippets = state.snippets.entities

  const sortedSnippets = [...snippets].sort((a, b) => {
    const timeA = new Date(a?.issued?.createdAt || '').getTime()
    const timeB = new Date(b?.issued?.createdAt || '').getTime()
    if (timeA > timeB) {
      return -1
    } else if (timeA < timeB) {
      return 1
    }
    return 0
  })
  return sortedSnippets
})
