import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import type {
  ParallaxHeroBlock,
  ButtonComponent,
  MediaComponent,
} from '@sceneio/schemas/lib/types'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { mergeDeepRight } from '@sceneio/tools'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { randomString } from '@sceneio/tools'
import { DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES } from '../../../components/Button/constants/globalButtonCssVariables'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from '../SplitHero/form/additionalFormFieldRenderers'

const MEDIA_DEFAULTS = mediaDefaults.config

export const buttonSampleFactory = (data = {}): ButtonComponent => ({
  id: randomString(8),
  children: textToLexical('Button', 'array-item'),
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
  ...data,
})

export const parallaxHeroComponentMeta: BlockComponentMeta<ParallaxHeroBlock> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        minHeight: {
          data: {
            '--min-height-default': 'auto',
          },
        },
        background: {
          fit: 'contain',
          parallax: true,
          responsiveConfig: {
            default: {
              image: {
                src: 'https://assets.scene.io/global/placeholders/parallax-hero-back-bg.png',
              },
            },
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'flex-start',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        padding: {
          data: {
            '--padding-xs-top': '3rem',
            '--padding-xs-bottom': '3rem',
            '--padding-default-left': '2rem',
            '--padding-default-right': '2rem',
            '--padding-default-top': '12rem',
            '--padding-default-bottom': '12rem',
          },
        },
        background: {
          fit: 'cover',
          parallax: false,
        },
        responsiveConfig: {
          default: {
            rowGap: '1.5rem',
            maxWidth: 'screen',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
      },
      type: 'blockContainer',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h1'],
        children: textToLexical('Medium length hero headline goes here', 'h1'),
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: {
          data: {
            '--padding-md-left': '0rem',
            '--padding-xs-left': '0rem',
            '--padding-md-right': '0rem',
            '--padding-xs-right': '0rem',
            '--padding-default-right': '15%',
            '--padding-default-left': '15%',
          },
        },
        as: 'h1',
        show: true,
      },
      type: 'text',
    },
    subheading: {
      path: 'components.subheading',
      name: 'Subheading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.subheading',
        },
      ],
      defaultConfig: {
        children: textToLexical('Subheading for a block'),
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        as: 'p',
        show: false,
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        ),
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: {
          data: {
            '--padding-md-left': '0rem',
            '--padding-xs-left': '0rem',
            '--padding-md-right': '0rem',
            '--padding-xs-right': '0rem',
            '--padding-default-top': 0,
            '--padding-default-right': '30%',
            '--padding-default-left': '30%',
            '--padding-default-bottom': 0,
          },
        },
        as: 'p',
        show: true,
      },
      type: 'text',
    },
    author: {
      path: 'components.author',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.author',
        },
      ],
      defaultConfig: {
        children: textToLexical('Author Name'),
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        as: 'p',
        show: false,
      },
      name: 'Author',
      type: 'text',
    },
    logo: {
      path: 'components.logo',
      name: 'Logo',
      defaultConfig: {
        responsiveConfig: {
          default: {
            src: 'https://assets.scene.io/global/placeholders/logo-ipsum-vertical.svg',
            width: 170,
          },
        },
        show: false,
      },
      configEditor: {
        formProps: {
          excludedSettings: ['height'],
        },
      },
      type: 'image',
    },
    background: {
      path: 'components.background',
      name: 'Media layer',
      components: {
        credits: {
          path: 'components.credits',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.background.components.credits',
            },
          ],
          disableEditing: true,
          name: 'Credits',
          type: 'text',
        },
      },
      defaultConfig: {
        ...(mergeDeepRight(MEDIA_DEFAULTS, {
          components: {
            ...MEDIA_DEFAULTS.components,
            image: {
              responsiveConfig: {
                default: {
                  src: 'https://assets.scene.io/global/placeholders/parallax-hero-front-bg.png',
                },
              },
            },
          },
          show: true,
          cover: true,
          format: 'image',
          animation: '',
        }) as MediaComponent),
      },
      type: 'media',
    },
    // social: {
    //   path: 'components.social',
    //   name: 'Social buttons',
    //   defaultConfig: {
    //     gap: 4,
    //     mode: 'share',
    //     variant: 'rounded',
    //     services: ['facebook', 'twitter'],
    //     show: true,
    //   },
    //   type: 'social',
    // },
    buttons: {
      path: 'components.buttons',
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Button',
          type: 'button',
          disableToggleShow: true,
          sampleFactory: buttonSampleFactory,
        },
      },
      inlineEditors: [
        {
          editorType: 'buttonEditor',
          editorPath: 'components.buttons.items',
          editorOptions: {
            isItemArray: true,
            sampleFactory: buttonSampleFactory,
          },
        },
      ],
      defaultConfig: {
        items: [
          buttonSampleFactory({
            children: textToLexical('CTA Button', 'array-item'),
            ...DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES,
          }),
          buttonSampleFactory(),
        ] as ButtonComponent[],
        gap: {
          data: {
            [`--row-gap-default`]: '1rem',
            [`--column-gap-default`]: '0.75rem',
          },
        },
        flow: {
          data: {
            '--flow-default': 'row',
            '--flow-xs': 'column',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'flex-start',
          },
        },
        padding: {
          data: {
            '--padding-default-top': '1rem',
          },
        },
        show: true,
      },
      name: 'Buttons',
      type: 'container',
      configEditor: {
        additionalFormFields: {
          layout: {
            formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
          },
        },
        formProps: {
          name: 'componentArray',
          registerValues:
            containerComponentFormRegisterValuesFactory('componentArray'),
        },
      },
    },
  }

export const parallaxHeroBlockMeta: BlockMeta<ParallaxHeroBlock> = {
  defaultConfig: {},
  configEditor: {},
}

export const parallaxHeroDefaultConfig =
  blockDefaultConfigFactory<ParallaxHeroBlock>({
    componentMeta: parallaxHeroComponentMeta,
    blockMeta: parallaxHeroBlockMeta,
    version: 1,
  })
