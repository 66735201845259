import { randomString } from '@sceneio/tools'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES } from '../Button/constants/globalButtonCssVariables'
import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'
import { FormComponent, InputFieldComponent } from '@sceneio/schemas/lib/types'

const messageSampleFactory = (text: string, color: string) => ({
  children: textToLexical(text),
  show: false,
  font: {
    data: {
      default: {
        '--font-default-size-default': '14px',
      },
    },
  },
  color: {
    data: {
      default: {
        '--color-default-default': color,
      },
    },
  },
  padding: {
    data: {
      '--padding-default-left': '8px',
    },
  },
})

const OPTIONAL_FIELD_LABEL_POSTFIX = ' (Optional)'

export const formFieldSampleFactory = ({
  name,
  label = 'Label text',
  showLabel = true,
  placeholder = 'Placeholder text',
  type = 'text',
  required = true,
  show = true,
}: {
  name: string
  label?: string
  showLabel?: boolean
  placeholder?: string
  type?: InputFieldComponent['type']
  required?: boolean
  show?: boolean
}): FormComponent['items'][0] => ({
  id: randomString(8),
  show: show,
  gap: {
    data: {
      '--row-gap-default': '8px',
    },
  },
  components: {
    label: {
      show: showLabel,
      children: textToLexical(
        required ? label : label.concat(OPTIONAL_FIELD_LABEL_POSTFIX),
      ),
      font: {
        data: {
          default: {
            '--font-default-size-default': '14px',
          },
        },
      },
      color: {
        data: {
          default: {
            '--color-default-default': '#6F6F6F',
          },
        },
      },
      padding: {
        data: {
          '--padding-default-left': '8px',
        },
      },
    },
    field: {
      type: type,
      components: {},
      border: {
        data: {
          default: {
            '--border-default-color-default': '#DCDCDC',
            '--border-default-width-default': '1px',
            '--border-default-style-default': 'solid',
            '--border-default-top-style-default': 'solid',
            '--border-default-right-style-default': 'solid',
            '--border-default-bottom-style-default': 'solid',
            '--border-default-left-style-default': 'solid',
            '--border-default-radius-top-left-default': '0px',
            '--border-default-radius-top-right-default': '0px',
            '--border-default-radius-bottom-left-default': '0px',
            '--border-default-radius-bottom-right-default': '0px',
          },
        },
      },
      padding: {
        data: {
          '--padding-default-top': '16px',
          '--padding-default-right': '16px',
          '--padding-default-bottom': '16px',
          '--padding-default-left': '16px',
        },
      },
      config: {
        name: name,
        placeholder: placeholder,
        required: required,
      },
      placeholderColor: {
        data: {
          default: {
            '--color-default-default': '#6F6F6F',
          },
        },
      },
    },
    button: {
      children: textToLexical('Submit'),
      width: {
        data: {
          '--width-default': 'auto',
          '--width-xs': '100%',
        },
      },
      margin: {
        data: {
          '--margin-default-left': '24px',
          '--margin-md-left': '24px',
          '--margin-xs-left': '0px',
          '--margin-xs-top': '16px',
        },
      },
      ...DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES,
    },
    requiredValueError: messageSampleFactory(
      'This field is required.',
      '#FB2A2A',
    ),
    invalidPatternError: messageSampleFactory('Invalid format', '#FB2A2A'),
    minLengthError: messageSampleFactory(
      'The minimum length for this field is 3 characters.',
      '#FB2A2A',
    ),
    maxLengthError: messageSampleFactory(
      'The maximum length for this field is 10 characters.',
      '#FB2A2A',
    ),
  },
})

export const formComponentMeta: ComponentMeta<'form'> = {
  defaultConfig: {
    settings: {
      provider: 'formspark',
    },
    items: [],
    components: {
      button: {
        children: textToLexical('Submit'),
        width: {
          data: {
            '--width-default': 'auto',
            '--width-xs': '100%',
          },
        },
        ...DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES,
      },
      successMessage: messageSampleFactory(
        'Your submission has been received!',
        '#2a9d8f',
      ),
      errorMessage: messageSampleFactory(
        'Oops, something has gone wrong.',
        '#FB2A2A',
      ),
    },
    flow: {
      data: {
        '--flow-default': 'column',
      },
    },
    horizontalAlignment: {
      data: {
        '--horizontal-alignment-default': 'center',
      },
    },
    verticalAlignment: {
      data: {
        '--vertical-alignment-default': 'flex-start',
      },
    },
    gap: {
      data: {
        '--column-gap-default': '24px',
        '--row-gap-default': '16px',
      },
    },
  },
}

export const formDefaults = componentDefaultConfigFactory<'form'>({
  componentMeta: formComponentMeta,
})
