import { BoxShadowArrayItem } from '@sceneio/schemas/lib/styles/boxShadowStyleSchema'
import { BreakpointType } from '@sceneio/ui-forms/lib/components/atoms/BreakpointDropdown/BreakpointDropdown'
import { StateType } from '@sceneio/ui-forms/lib/components/atoms/StateTabs/StateTabs'

type ShadowConfig = BoxShadowArrayItem

type ShadowType = {
  responsiveConfig?: {
    [bp in BreakpointType | 'default']?: {
      [state in StateType]?: ShadowConfig[]
    }
  }
}

const generateShadow = (
  state: ShadowConfig[] = [],
  shadowType: string,
  fallbackShadow?: Record<string, any>,
) => {
  let shadowString = ''
  state.forEach((shadow) => {
    const {
      x = fallbackShadow?.x,
      y = fallbackShadow?.y,
      blur = fallbackShadow?.blur,
      spread = fallbackShadow?.spread,
      color = fallbackShadow?.color,
      inset = fallbackShadow?.inset,
    } = shadow || {}
    const shadowList = shadowString ? shadowString.split(',') : []
    const shadowLength = shadowList.length
    shadowList[shadowLength] =
      shadowType === 'box'
        ? `${x} ${y} ${blur} ${spread} ${color}${inset ? ' inset' : ''}`
        : `${x} ${y} ${blur} ${color}`
    shadowString = shadowList.join(', ')
  })
  return shadowString
}

export const getShadowCssVariables = ({
  shadow,
  shadowType,
  prefix,
}: {
  shadow?: ShadowType
  shadowType: 'box' | 'text'
  prefix: string
}) => {
  if (!shadow) {
    return {}
  }
  const { responsiveConfig } = shadow || {}
  if (!responsiveConfig || !Object.entries(responsiveConfig)?.length) return {}

  const cssVars: Record<string, any> = {}
  Object.entries(responsiveConfig).forEach(([key, value]) => {
    const breakpoint = key
    const {
      default: defaultState,
      hover: hoverState,
      active: activeState,
    } = value || {}

    const fallbackShadow = responsiveConfig?.default?.default?.[0]
    cssVars[`--${prefix}-${shadowType}-shadow-${breakpoint}-default`] =
      generateShadow(defaultState, shadowType, fallbackShadow)
    cssVars[`--${prefix}-${shadowType}-shadow-${breakpoint}-hover`] =
      generateShadow(hoverState, shadowType, fallbackShadow)
    cssVars[`--${prefix}-${shadowType}-shadow-${breakpoint}-active`] =
      generateShadow(activeState, shadowType, fallbackShadow)
  })

  return cssVars
}
