import { MediaBlock } from '@sceneio/schemas/lib/types'
import { BlockComponentMeta, BlockMeta } from '../blockCommonTypes'
import { blockDefaultConfigFactory } from '../helpers'
import { mediaDefaults } from './../../components/Media/mediaMeta'

const MEDIA_DEFAULTS = mediaDefaults.config

export const mediaComponentMeta: BlockComponentMeta<MediaBlock> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      responsiveConfig: {
        default: {
          maxWidth: '80%',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
    type: 'blockContainer',
  },
  media: {
    path: 'components.media',
    name: 'Media',
    disableToggleShow: true,
    defaultConfig: MEDIA_DEFAULTS,
    type: 'media',
    components: {
      credits: {
        path: 'components.credits',
        inlineEditors: [
          {
            editorType: 'inlineText',
            editorPath: 'components.media.components.credits',
          },
        ],
        disableEditing: true,
        name: 'Credits',
        type: 'text',
      },
    },
  },
  caption: {
    path: 'components.media.components.caption',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.media.components.caption',
      },
    ],
    defaultConfig: { ...MEDIA_DEFAULTS?.components.caption, show: false },
    name: 'Caption',
    type: 'text',
  },
}

export const mediaBlockMeta: BlockMeta<MediaBlock> = {}

export const mediaDefaultConfig = blockDefaultConfigFactory<MediaBlock>({
  componentMeta: mediaComponentMeta,
  blockMeta: mediaBlockMeta,
  version: 1,
})
