import { array, object } from 'yup'
import { strictContentBlockSchema } from './contentBlockSchema'

const contentBlocksArraySchema = array().of(strictContentBlockSchema)

export const contentBlocksSchema = object({
  contentBlocks: contentBlocksArraySchema,
})
  .noUnknown()
  .required()
