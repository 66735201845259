import { InferType, object, reach, string } from 'yup'
import { fontStyleSchema } from '../../styles/fontStyleSchema'
import { widthStyleSchema } from '../../styles/widthStyleSchema'
import { paddingStyleSchema } from '../../styles/paddingStyleSchema'
import { marginStyleSchema } from '../../styles/marginStyleSchema'
import { colorStyleSchema } from '../../styles/colorStyleSchema'
import { backgroundColorStyleSchema } from '../../styles/backgroundColorStyleSchema'
import { textShadowStyleSchema } from '../../styles/textShadowStyleSchema'
import { boxShadowStyleSchema } from '../../styles/boxShadowStyleSchema'
import { borderStyleSchema } from '../../styles/borderStyleSchema'
import { iconComponentSchema } from '../../components/iconComponentSchema'

export const buttonSnippetSchema = object({
  data: object({
    value: object({
      font: fontStyleSchema,
      width: widthStyleSchema,
      padding: paddingStyleSchema,
      margin: marginStyleSchema,
      color: colorStyleSchema,
      backgroundColor: backgroundColorStyleSchema,
      textShadow: textShadowStyleSchema,
      boxShadow: boxShadowStyleSchema,
      border: borderStyleSchema,
    }).noUnknown(),
  })
    .noUnknown()
    .required(),
  type: string().oneOf(['BUTTON']).required(),
})

export type ButtonSnippet = InferType<typeof buttonSnippetSchema>
