import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Text from '../../../components/Text/Text'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import Grid from '../../../components/Grid/Grid'
import Card from '../../../components/Card/Card'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import {
  buttonSampleFactory,
  featureGrid2DefaultConfig,
} from './featureGrid2Meta'
import type { FeatureGrid2Block } from '@sceneio/schemas/lib/types'
import Media from '../../../components/Media/Media'
import { Container } from '../../../components/Container/Container'
import Button from '../../../components/Button/Button'
import { editorItemsControlsFactory } from '../../../tools/editor/editor'

function FeatureGrid2({
  id,
  components: { block, tagline, heading, text, cards, buttons, media },
  editor,
  preview,
}: FeatureGrid2Block = featureGrid2DefaultConfig) {
  const { className, swapOrder, columnRatio, ...restBlockProps } = block || {}
  const featureGrid2Class = clsx('sc-feature-grid-2', className)

  const contentColumns = columnRatio!
  const mediaColumns = 12 - contentColumns

  const mediaColumnClasses = clsx('sc-feature-grid-2__media-column', {
    [`bui-col-md-${mediaColumns}`]: mediaColumns,
  })

  const contentColumnClasses = clsx(`sc-feature-grid-2__content-column`, {
    [`bui-col-md-${contentColumns}`]: contentColumns,
    [`bui-col`]: contentColumns === 0,
    'bui-order-md-last': swapOrder,
  })

  return (
    <Block
      className={featureGrid2Class}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <div className="bui-row">
        <div className={contentColumnClasses}>
          <Text
            {...tagline}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'tagline',
            }}
            cid={resolveComponentId(id, 'tagline')}
          />
          <Text
            {...heading}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'heading',
            }}
            cid={resolveComponentId(id, 'heading')}
          />
          <Text
            {...text}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'text',
            }}
            cid={resolveComponentId(id, 'text')}
          />
          <Grid
            {...cards}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'cards',
            }}
            items={cards?.items?.map((item, index) => (
              <Card
                key={item.id}
                editor={{
                  ...editor?.component,
                  blockId: id,
                  id: `cards.items[${index}]`,
                }}
                cid={resolveComponentId(id, `cards.items[${index}]`)}
                preview={preview}
                {...item}
              />
            ))}
          />
          <Container
            className="bui-flex-wrap bui-w-100"
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'buttons',
            }}
            {...buttons}
            name="componentArray"
          >
            {buttons?.items?.map(
              (
                {
                  id: buttonId,
                  childrenWrapProps = {},
                  className: buttonClassname,
                  ...restButtonProps
                },
                index,
              ) => (
                <Button
                  editor={{
                    ...editor?.component,
                    blockId: id,
                    id: `buttons.items[${index}]`,
                    wysiwyg: {
                      type: 'ITEMS',
                      ...editorItemsControlsFactory({
                        itemsArr: buttons.items || [],
                        itemsId: `buttons.items`,
                        itemFactory: buttonSampleFactory,
                        index: index,
                      }),
                    },
                  }}
                  className={clsx('sc-feature-grid-2__button', buttonClassname)}
                  childrenWrapProps={{
                    ...childrenWrapProps,
                  }}
                  key={buttonId}
                  cid={resolveComponentId(id, `button-${index}`)}
                  {...restButtonProps}
                />
              ),
            )}
          </Container>
        </div>
        <div className={mediaColumnClasses}>
          <Media
            {...media}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'media',
            }}
            components={{
              ...media.components,
              caption: {
                show: false,
              },
            }}
            className="sc-feature-grid-2__media"
            cid={resolveComponentId(id, 'media')}
            preview={preview}
          />
        </div>
      </div>
    </Block>
  )
}
export const featureGrid2Plugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}

export default FeatureGrid2
