import { RHFShadowSettingsPropType } from './ShadowSettings'
import { BreakpointType } from '../../atoms/BreakpointDropdown/BreakpointDropdown'
import { search } from 'jmespath'
import { unflatten } from 'flat'
import { StateType } from '../../atoms/StateTabs/StateTabs'
import { randomString } from '@sceneio/tools'

const SHADOW_PROPERTY_POSITION_MAP: Record<
  'box' | 'text',
  Record<string, number>
> = {
  box: {
    X: 0,
    Y: 1,
    blur: 2,
    spread: 3,
    color: 4,
    inset: 5,
  },
  text: {
    X: 0,
    Y: 1,
    blur: 2,
    color: 3,
  },
}

export const getShadows = (
  shadow: string,
  noneShadowFallback: string,
  shadowType: RHFShadowSettingsPropType['shadowType'],
) => {
  let shadowString = shadow
  if (!shadow || shadow === 'none') {
    shadowString = noneShadowFallback
  }
  const shadowsRegex =
    shadowType === 'box'
      ? /(?:[\d.]+(?:px|rem|em)\s+){4}(rgba\([^)]+\)|#[0-9a-fA-F]{3,6})(\sinset)?/g
      : /(?:[\d.]+(?:px|rem|em)\s+){3}(rgba\([^)]+\)|#[0-9a-fA-F]{3,6})/g

  // Use the regular expression to find all shadow definitions in the input string
  const shadowList = shadowString.match(shadowsRegex) || [shadowString]
  return shadowList
}

export const parseShadow = ({
  shadow,
  index,
  noneShadowFallback,
  shadowType,
}: {
  shadow: string
  index: number
  noneShadowFallback: string
  shadowType: RHFShadowSettingsPropType['shadowType']
}) => {
  if (!shadow) return {}
  const shadowList = getShadows(shadow, noneShadowFallback, shadowType)
  const shadowRegex =
    shadowType === 'box'
      ? /([\d.]+px|[\d.]+rem|[\d.]+em) ([\d.]+px|[\d.]+rem|[\d.]+em) ([\d.]+px|[\d.]+rem|[\d.]+em) ([\d.]+px|[\d.]+rem|[\d.]+em) (rgba\([^)]+\)|#[0-9a-fA-F]{3,6})(?:\s*)(inset)?/
      : /([\d.]+px|[\d.]+rem|[\d.]+em) ([\d.]+px|[\d.]+rem|[\d.]+em) ([\d.]+px|[\d.]+rem|[\d.]+em) (rgba\([^)]+\)|#[0-9a-fA-F]{3,6})/

  if (shadowType === 'box') {
    const [, X, Y, blur, spread, color, inset] =
      shadowList[index]?.match(shadowRegex) ||
      shadowList[0].match(shadowRegex) ||
      []

    return { X, Y, blur, spread, color, inset }
  } else {
    const [, X, Y, blur, color] =
      shadowList[index]?.match(shadowRegex) ||
      shadowList[0].match(shadowRegex) ||
      []

    return { X, Y, blur, color }
  }
}

export const generateShadow = ({
  name,
  value,
  shadow,
  index,
  noneShadowFallback,
  shadowType,
}: {
  name: string
  value: any
  shadow: string
  index: number
  noneShadowFallback: string
  shadowType: RHFShadowSettingsPropType['shadowType']
}) => {
  var shadowList = getShadows(shadow, noneShadowFallback, shadowType)
  var result = Object.values(
    parseShadow({ shadow, index, noneShadowFallback, shadowType }),
  )

  if (name === 'inset') {
    result[SHADOW_PROPERTY_POSITION_MAP[shadowType][name]] = value
      ? 'inset'
      : ''
  } else if (name === 'color' && value) {
    result[SHADOW_PROPERTY_POSITION_MAP[shadowType][name]] = value
  } else if (value) {
    result[SHADOW_PROPERTY_POSITION_MAP[shadowType][name]] = value
  }

  shadowList[index] = result.join(' ')

  return shadowList.join(', ')
}

export const getNormalizedRegisterValues = ({
  valuesPath,
  registerValues,
  availableStates,
}: {
  registerValues: Record<string, any>
  valuesPath: string
  availableStates: StateType[]
}) => {
  // We have to assign a unique id to every shadow in the shadows array for snippets referencing purposes.
  const responsiveConfig: Record<string, any> = search(
    unflatten(registerValues),
    `${valuesPath}`,
  )

  //TODO resolve placeholders for states
  Object.keys(responsiveConfig).map((bp) => {
    availableStates.map((state) => {
      responsiveConfig[bp][state] = (
        (responsiveConfig[bp][state] || [{}]) as Record<string, any>[]
      )?.map((shadow) => ({
        ...shadow,
        id: randomString(8),
      }))
    })
  })

  return unflatten({ [valuesPath]: responsiveConfig }) as Record<string, any>
}

export const getBreakpointValuesWithUniqueId = ({
  breakpoint,
  placeholders,
  valuesPath,
}: {
  breakpoint: BreakpointType
  placeholders: Record<string, any>
  valuesPath: string
}) => {
  const responsiveConfig: Record<string, any> = search(
    unflatten(placeholders),
    `${valuesPath}.${breakpoint}`,
  )

  Object.entries(responsiveConfig)?.map(([state, shadows]) => {
    responsiveConfig[state] = (shadows as Record<string, any>[])?.map(() => ({
      id: randomString(8),
    }))
  })

  return { [`${valuesPath}.${breakpoint}`]: responsiveConfig }
}
