import type { SVGProps } from 'react'
const SvgSidesBlr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#252523"
      fillRule="evenodd"
      d="M4.65 2.5A.35.35 0 0 1 5 2.15h.75a.35.35 0 1 1 0 .7H5a.35.35 0 0 1-.35-.35m2.25 0a.35.35 0 0 1 .35-.35h1.5a.35.35 0 1 1 0 .7h-1.5a.35.35 0 0 1-.35-.35m3 0a.35.35 0 0 1 .35-.35H11a.35.35 0 1 1 0 .7h-.75a.35.35 0 0 1-.35-.35M4 13.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M13.5 4a.5.5 0 0 1 .5.5v7a.5.5 0 1 1-1 0v-7a.5.5 0 0 1 .5-.5M2.5 4a.5.5 0 0 1 .5.5v7a.5.5 0 1 1-1 0v-7a.5.5 0 0 1 .5-.5"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgSidesBlr
