import { MouseEventHandler } from 'react'
import { InferType, bool, mixed, number, object, string } from 'yup'
import { ariaAttributesSchema } from '../shared/ariaAttributesSchema'
import { ariaRoleSchema } from '../shared/ariaRoleSchema'
import { idSchema } from '../shared/idSchema'
import { reactNodeSchema } from '../shared/reactNodeSchema'
import { styleSchema } from '../shared/styleSchema'
import { backgroundColorStyleSchema } from '../styles/backgroundColorStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { colorStyleSchema } from '../styles/colorStyleSchema'
import { fontStyleSchema } from '../styles/fontStyleSchema'
import { horizontalAlignmentFlexStyleSchema } from '../styles/horizontalAlignmentFlexStyleSchema'
import { linkStyleSchema } from '../styles/linkStyleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { textShadowStyleSchema } from '../styles/textShadowStyleSchema'
import { widthStyleSchema } from '../styles/widthStyleSchema'
import { enableEditorSchema } from './common/enableEditorSchema'
import { linkSchema } from './common/linkSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { iconComponentSchema } from './iconComponentSchema'

export const buttonComponentSchema = object({
  children: reactNodeSchema,
  components: object({
    icon: iconComponentSchema,
  })
    .noUnknown()
    .optional(),
  className: string(),
  disabled: bool(),
  width: widthStyleSchema.optional(),
  font: fontStyleSchema.optional(),
  cid: string(),
  id: string(),
  'data-bs-toggle': string().oneOf(['collapse']),
  onClick: mixed<MouseEventHandler<HTMLButtonElement>>(),
  onMouseDown: mixed<MouseEventHandler<HTMLButtonElement>>(),
  dataAttributes: object().optional(),
  ariaAttributes: ariaAttributesSchema,
  isAnimated: bool(),
  enableEditor: enableEditorSchema,
  editor: componentEditorSchema,
  link: linkSchema.optional(),
  horizontalAlignment: horizontalAlignmentFlexStyleSchema.optional(),
  color: colorStyleSchema.optional(),
  backgroundColor: backgroundColorStyleSchema.optional(),
  style: styleSchema,
  type: string().oneOf(['button', 'submit', 'reset']),
  textShadow: textShadowStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  linkStyle: linkStyleSchema.optional(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  as: string().oneOf(['div', 'button']),
  childrenWrapProps: object({
    className: string(),
    dataAttributes: object().optional(),
  })
    .noUnknown()
    .optional(),
  iconWrapProps: object({
    className: string(),
  })
    .noUnknown()
    .optional(),
  tabIndex: number(),
  show: bool(),
}).noUnknown()

export type ButtonComponent = InferType<
  typeof buttonComponentSchema & {
    as?: keyof JSX.IntrinsicElements
  }
>

export type ButtonComponentStyles = Pick<
  ButtonComponent,
  | 'width'
  | 'font'
  | 'horizontalAlignment'
  | 'color'
  | 'backgroundColor'
  | 'border'
  | 'boxShadow'
  | 'textShadow'
  | 'margin'
  | 'padding'
>
