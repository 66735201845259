import clsx from 'clsx'
import { SegmentWrapper } from '../../atoms/SegmentWrapper/SegmentWrapper'
import {
  FormContextProvider,
  useFormContext,
} from '../../form/context/FormContext'
import { RHFShadowSettingsPropType } from './ShadowSettings'
import { SnippetHeader } from '../../atoms/SnippetHeader/SnippetHeader'
import { useEffect, useState } from 'react'
import { stringify } from '@sceneio/tools'
import { HeaderSegment } from '../../atoms/HeaderSegment/HeaderSegment'
import { Icon } from '@sceneio/ui-icons'
import { SaveButton } from '../../atoms/SaveButton/SaveButton'
import { BreakpointType } from '../../atoms/BreakpointDropdown/BreakpointDropdown'
import { RHFStateTabs, StateType } from '../../atoms/StateTabs/StateTabs'
import { BreakpointShadowFields } from './components/BreakpointShadowFields'
import { InheritedSettingsWrapper } from '../../atoms/InheritedSettingsWrapper/InheritedSettingsWrapper'

const StateFields = ({
  shadowType,
  isBreakpointActive,
  forceBreakpoint,
  hasDefaultValues,
  valuesPath,
  state,
  onEditDefaultFrameClick,
}: {
  shadowType: RHFShadowSettingsPropType['shadowType']
  isBreakpointActive: boolean
  forceBreakpoint?: BreakpointType
  hasDefaultValues: boolean
  valuesPath: string
  state: StateType
  onEditDefaultFrameClick: (arg0: { focusedMolecule?: string }) => void
}) => (
  <>
    {isBreakpointActive && (
      <BreakpointShadowFields
        shadowType={shadowType}
        breakpoint={forceBreakpoint!}
        state={state}
        valuesPath={valuesPath}
      />
    )}
    {(hasDefaultValues || isBreakpointActive) && (
      <InheritedSettingsWrapper
        onEditClick={() =>
          onEditDefaultFrameClick({ focusedMolecule: shadowType })
        }
      >
        <BreakpointShadowFields
          shadowType={shadowType}
          breakpoint="default"
          state={state}
          valuesPath={valuesPath}
        />
      </InheritedSettingsWrapper>
    )}
  </>
)

export const ResponsiveShadowSettings = ({
  basePath,
  title = 'Shadow',
  className,
  stateTabs: {
    inheritedState,
    allowedInheritedStates = [],
    allowHover = false,
    allowActive = false,
    activeStateLabel = 'Active',
  } = {},
  shadowType,
}: RHFShadowSettingsPropType) => {
  const valuesPath = `${
    basePath ? basePath + '.responsiveConfig' : 'responsiveConfig'
  }`

  const {
    unregister,
    values,
    register,
    registerValues,
    placeholders,
    watch,
    onCreateSnippet,
    onDetachSnippet,
    onAssignSnippet,
    onDeleteSnippet,
    onIssueSnippet,
    onDiscardSnippet,
    onEditDefaultFrameClick,
    parentSnippet,
    meta,
    allowSaveSnippet,
    forceBreakpoint,
  } = useFormContext({
    snippetsType: 'SHADOW',
    metaPath: valuesPath,
    name: basePath,
  })

  const [isBreakpointActive, setIsBreakpointActive] = useState(false)

  const isSnippet = Object.keys(meta).length > 0
  const hasSnippetUnpublishedChanges = meta.hasUnpublishedChanges
  const snippetId = meta.id

  const hasBreakpointValues = Object.keys(values).some((val) =>
    val.startsWith(`${valuesPath}.${forceBreakpoint}`),
  )

  const hasDefaultValues = Object.keys(values).some((val) =>
    val.startsWith(`${valuesPath}.default`),
  )

  const showSettings = hasDefaultValues || hasBreakpointValues

  useEffect(() => {
    if (hasBreakpointValues) {
      setIsBreakpointActive(true)
    } else {
      setIsBreakpointActive(false)
    }
  }, [stringify(hasBreakpointValues)])

  const onAssignSnippetCallback = (
    id: string,
    options?: { shouldReplace?: boolean },
  ) => {
    onAssignSnippet({
      id,
      fieldName: valuesPath,
      parentSnippetId:
        (options?.shouldReplace ? undefined : snippetId) || parentSnippet?.id,
      parentSnippetPath: parentSnippet?.path,
    })
  }

  const onDetachSnippetCallback = () => {
    onDetachSnippet({
      id: snippetId,
      fieldName: valuesPath,
      parentSnippetId: parentSnippet?.id,
      parentSnippetPath: parentSnippet?.path,
    })
  }

  return (
    <FormContextProvider
      parentSnippet={{
        id: snippetId,
        path: meta.snippetPath,
        hasUnpublishedChanges: hasSnippetUnpublishedChanges,
      }}
    >
      <SegmentWrapper className={clsx('sc-bp-shadow-settings', className)}>
        {isSnippet ? (
          <SnippetHeader
            snippetType={shadowType === 'box' ? 'BOX_SHADOW' : 'TEXT_SHADOW'}
            snippetName={meta.name}
            snippetId={snippetId}
            snippetHasOverrides={hasSnippetUnpublishedChanges}
            editSnippetMode={true}
            onAssignSnippet={onAssignSnippetCallback}
            detachButton={{
              show: true,
              onClick: onDetachSnippetCallback,
            }}
            editButton={{
              show: !hasBreakpointValues,
              onClick: () => setIsBreakpointActive(!isBreakpointActive),
            }}
            customChildren={
              <>
                {hasBreakpointValues && (
                  <button
                    type="button"
                    className="tw-p-1"
                    onClick={() => {
                      unregister([`${valuesPath}.${forceBreakpoint}`], {
                        customData: {
                          parentSnippetId: snippetId,
                          parentSnippetPath: meta.snippetPath,
                        },
                      })
                    }}
                  >
                    Reset
                  </button>
                )}
              </>
            }
            // removeButton={{
            //   show: true,
            //   onClick: () => unregister([`${valuesPath}`]),
            // }}
            resetButton={{
              show: hasSnippetUnpublishedChanges,
              onClick: () => {
                onDiscardSnippet({ id: snippetId })
              },
            }}
          />
        ) : (
          <HeaderSegment
            heading={title}
            addButton={{
              show: !hasDefaultValues && !hasBreakpointValues,
              onClick: () => {
                register(registerValues)
                setIsBreakpointActive(true)
              },
            }}
            customChildren={
              <>
                {hasBreakpointValues && (
                  <button
                    type="button"
                    className="tw-p-1"
                    onClick={() => {
                      unregister([`${valuesPath}.${forceBreakpoint}`], {
                        customData: {
                          parentSnippetId: snippetId,
                          parentSnippetPath: meta.snippetPath,
                        },
                      })
                    }}
                  >
                    Reset
                  </button>
                )}
                {hasDefaultValues && !hasBreakpointValues && (
                  <button
                    type="button"
                    className="tw-p-1"
                    onClick={() => {
                      setIsBreakpointActive(!isBreakpointActive)
                    }}
                  >
                    <Icon provider="phosphor" icon="PencilSimple" />
                  </button>
                )}
              </>
            }
            snippetsDropdown={{
              show: true,
              snippetsType: shadowType === 'box' ? 'BOX_SHADOW' : 'TEXT_SHADOW',
              showSaveButton: hasDefaultValues || hasBreakpointValues,
              onCreateSnippet: (snippetName) => {
                onCreateSnippet({
                  name: snippetName,
                  data: {
                    value: watch(valuesPath),
                  },
                  type: shadowType === 'box' ? 'BOX_SHADOW' : 'TEXT_SHADOW',
                  fieldName: valuesPath,
                  parentSnippetId: snippetId || parentSnippet?.id,
                  parentSnippetPath: parentSnippet?.path,
                })
              },
              onDeleteSnippet,
              onAssignSnippet: onAssignSnippetCallback,
            }}
          />
        )}
        {showSettings && (
          <div className="tw-flex tw-flex-col tw-gap-2">
            {!allowHover && !allowActive && !inheritedState && (
              <StateFields
                shadowType={shadowType}
                valuesPath={valuesPath}
                forceBreakpoint={forceBreakpoint}
                isBreakpointActive={isBreakpointActive}
                hasDefaultValues={hasDefaultValues}
                state={'default'}
                onEditDefaultFrameClick={onEditDefaultFrameClick}
              />
            )}
            {inheritedState && (
              <StateFields
                shadowType={shadowType}
                valuesPath={valuesPath}
                forceBreakpoint={forceBreakpoint}
                isBreakpointActive={isBreakpointActive}
                hasDefaultValues={hasDefaultValues}
                state={inheritedState}
                onEditDefaultFrameClick={onEditDefaultFrameClick}
              />
            )}
            {!inheritedState && (allowHover || allowActive) && (
              <RHFStateTabs
                disabledTabs={[
                  ...(!allowHover ? ['hover'] : []),
                  ...(!allowActive ? ['active'] : []),
                ]}
                activeStateLabel={activeStateLabel}
                defaultStateTabContent={
                  <StateFields
                    shadowType={shadowType}
                    valuesPath={valuesPath}
                    forceBreakpoint={forceBreakpoint}
                    isBreakpointActive={isBreakpointActive}
                    hasDefaultValues={hasDefaultValues}
                    state={'default'}
                    onEditDefaultFrameClick={onEditDefaultFrameClick}
                  />
                }
                hoverStateTabContent={
                  <StateFields
                    shadowType={shadowType}
                    valuesPath={valuesPath}
                    forceBreakpoint={forceBreakpoint}
                    isBreakpointActive={isBreakpointActive}
                    hasDefaultValues={hasDefaultValues}
                    state={'hover'}
                    onEditDefaultFrameClick={onEditDefaultFrameClick}
                  />
                }
                activeStateTabContent={
                  <StateFields
                    shadowType={shadowType}
                    valuesPath={valuesPath}
                    forceBreakpoint={forceBreakpoint}
                    isBreakpointActive={isBreakpointActive}
                    hasDefaultValues={hasDefaultValues}
                    state={'active'}
                    onEditDefaultFrameClick={onEditDefaultFrameClick}
                  />
                }
              />
            )}
            {hasSnippetUnpublishedChanges && allowSaveSnippet && (
              <SaveButton
                onClick={() => {
                  onIssueSnippet({ id: snippetId, name: meta.name })
                }}
              />
            )}
          </div>
        )}
      </SegmentWrapper>
    </FormContextProvider>
  )
}
