import { BlockComponent, SplitHero2Block } from '@sceneio/schemas/lib/types'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { splitHeroComponentMeta } from '../SplitHero/splitHeroMeta'
import { blockLayoutAdditionalFormFieldRenderer } from '../SplitHero/form/additionalFormFieldRenderers'

const MEDIA_DEFAULTS = mediaDefaults.config

export const splitHero2ComponentMeta: BlockComponentMeta<SplitHero2Block> = {
  ...splitHeroComponentMeta,
  block: {
    ...splitHeroComponentMeta.block,
    type: 'block',
    defaultConfig: {
      ...splitHeroComponentMeta.block.defaultConfig,
      swapOrder: true,
    } as BlockComponent & {
      swapOrder?: boolean
    },
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: blockLayoutAdditionalFormFieldRenderer,
        },
      },
    },
  },
  blockContainer: {
    ...splitHeroComponentMeta.blockContainer,
    defaultConfig: {
      responsiveConfig: {
        default: {
          maxWidth: 'screen',
          rowGap: '1.5rem',
        },
      },
    },
    type: 'blockContainer',
  },
  contentContainer: {
    ...splitHeroComponentMeta.contentContainer,
    type: 'container',
    defaultConfig: {
      ...splitHeroComponentMeta.contentContainer.defaultConfig,
      padding: {
        data: {
          '--padding-default-left': '4rem',
          '--padding-default-right': '4rem',
          '--padding-default-top': '2rem',
          '--padding-default-bottom': '2rem',
          '--padding-xs-left': '2rem',
          '--padding-xs-right': '2rem',
        },
      },
    },
  },
  media: {
    ...splitHeroComponentMeta.media,
    defaultConfig: {
      ...MEDIA_DEFAULTS,
      cover: true,
      components: {
        ...MEDIA_DEFAULTS.components,
        image: {
          ...MEDIA_DEFAULTS.components.image,
          responsiveConfig: {
            default: {
              src: 'https://assets.scene.io/global/placeholders/media-720x900.png',
            },
          },
          transformationOptions: {
            cover: '-x100p',
          },
        },
      },
    },
    type: 'media',
  },
}

export const splitHero2BlockMeta: BlockMeta<SplitHero2Block> = {}

export const splitHero2DefaultConfig =
  blockDefaultConfigFactory<SplitHero2Block>({
    componentMeta: splitHero2ComponentMeta,
    blockMeta: splitHero2BlockMeta,
    version: 1,
  })
