import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import type {
  BlockComponent,
  LogoStrip2Block,
} from '@sceneio/schemas/lib/types'
import {
  logoImageSampleFactory,
  logoStripComponentMeta,
} from '../LogoStrip/logoStripMeta'

export const logoStrip2ComponentMeta: BlockComponentMeta<LogoStrip2Block> = {
  ...logoStripComponentMeta,
  block: {
    ...logoStripComponentMeta.block,
    type: 'block',
    defaultConfig: {
      ...logoStripComponentMeta.block.defaultConfig,
      swapOrder: false,
      columnRatio: 12,
    } as BlockComponent & {
      swapOrder?: boolean
      columnRatio: number
    },
    configEditor: {},
  },
  blockContainer: {
    ...logoStripComponentMeta.blockContainer,
    defaultConfig: {
      ...logoStripComponentMeta.blockContainer.defaultConfig,
      responsiveConfig: {
        default: {
          maxWidth: '100%',
          columnGap: '3rem',
          rowGap: '2.5rem',
        },
        xs: {
          maxWidth: 'screen',
        },
        sm: {
          maxWidth: 'screen',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
  },
  heading: {
    ...logoStripComponentMeta.heading,
    type: 'text',
    name: 'Text',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.heading',
      },
    ],
    defaultConfig: {
      ...logoStripComponentMeta.heading.defaultConfig,
      as: 'p',
      font: {
        data: {
          default: {
            '--font-default-weight-default': 600,
            '--font-default-size-default': '18px',
          },
        },
      },
    },
    disableToggleShow: false,
  },
  logoImages: {
    ...logoStripComponentMeta.logoImages,
    defaultConfig: {
      ...logoStripComponentMeta.logoImages.defaultConfig,
      items: [
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
        logoImageSampleFactory(),
      ],
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
          '--horizontal-alignment-xs': 'center',
        },
      },
      columns: {
        data: {
          '--columns-default': 6,
          '--columns-md': 4,
          '--columns-xs': 2,
        },
      },
    },
  },
}

export const logoStrip2BlockMeta: BlockMeta<LogoStrip2Block> = {}

export const logoStrip2DefaultConfig =
  blockDefaultConfigFactory<LogoStrip2Block>({
    componentMeta: logoStrip2ComponentMeta,
    blockMeta: logoStrip2BlockMeta,
    version: 1,
  })
