import { InferType, array, bool, mixed, object, string } from 'yup'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { styleSchema } from '../shared/styleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'
import { columnsStyleSchema } from '../styles/columnsStyleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'

export const masonryGridComponentSchema = object({
  items: array().of(mixed<any>()),
  columns: columnsStyleSchema.optional(),
  columnClassName: string(),
  className: string(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  gap: gapStyleSchema.optional(),
  show: bool(),
  style: styleSchema,
  editor: componentEditorSchema,
}).noUnknown()

export type MasonryGridComponent = InferType<typeof masonryGridComponentSchema>

export type MasonryGridComponentStyles = Pick<
  MasonryGridComponent,
  'margin' | 'padding' | 'boxShadow' | 'border' | 'columns' | 'gap'
>
