import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import type {
  BlockComponent,
  FeatureWithFormBlock,
} from '@sceneio/schemas/lib/types'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { blockLayoutAdditionalFormFieldRenderer } from '../../Hero/SplitHero/form/additionalFormFieldRenderers'
import {
  formDefaults,
  formFieldSampleFactory,
} from '../../../components/Form/formMeta'

const MEDIA_DEFAULTS = mediaDefaults.config
const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config
const FORM_DEFAULTS = formDefaults.config

export const featureWithFormComponentMeta: BlockComponentMeta<FeatureWithFormBlock> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        swapOrder: false,
        columnRatio: 6,
      } as BlockComponent & {
        swapOrder?: boolean
        columnRatio: number
      },
      configEditor: {
        additionalFormFields: {
          layout: {
            formFieldRenderer: blockLayoutAdditionalFormFieldRenderer,
          },
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        responsiveConfig: {
          default: {
            maxWidth: 'screen',
            rowGap: '1.5rem',
            columnGap: '2.5rem',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        padding: BLOCK_CONTAINER_DEFAULTS.padding,
      },
      type: 'blockContainer',
    },
    tagline: {
      path: 'components.tagline',
      name: 'Tagline',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.tagline',
        },
      ],
      defaultConfig: {
        children: textToLexical('Tagline'),
        as: 'p',
        margin: {
          data: {
            '--margin-default-bottom': '1.5rem',
          },
        },
        font: {
          data: {
            default: {
              '--font-default-weight-default': 'bold',
            },
          },
        },
      },
      type: 'text',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
        children: textToLexical(
          'Medium length section heading goes here',
          'h2',
        ),
        as: 'h2',
        margin: {
          data: {
            '--margin-default-bottom': '1.5rem',
          },
        },
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
        ),
        as: 'p',
        margin: {
          data: {
            '--margin-default-bottom': '2rem',
          },
        },
      },
      type: 'text',
    },
    media: {
      path: 'components.media',
      name: 'Media',
      components: {
        credits: {
          path: 'components.credits',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.media.components.credits',
            },
          ],
          disableEditing: true,
          name: 'Credits',
          type: 'text',
        },
      },
      defaultConfig: MEDIA_DEFAULTS,
      type: 'media',
    },
    bottomText: {
      path: 'components.bottomText',
      name: 'Bottom Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.bottomText',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        ),
        as: 'p',
        show: true,
        margin: {
          data: {
            '--margin-default-top': '1.5rem',
          },
        },
        color: {
          data: {
            default: {
              '--color-default-default': '#6F6F6F',
            },
          },
        },
        font: {
          data: {
            default: {
              '--font-default-size-default': '14px',
            },
          },
        },
      },
      type: 'text',
    },
    form: {
      type: 'form',
      path: 'components.form',
      name: 'Form',
      disableToggleShow: true,
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Email field',
          type: 'formField',
          disableToggleShow: true,
          components: {
            label: {
              path: 'components.label',
              name: 'Label',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.label',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            field: {
              path: 'components.field',
              name: 'Input',
              type: 'input',
            },
            requiredValueError: {
              path: 'components.requiredValueError',
              name: 'Required value',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.requiredValueError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            invalidPatternError: {
              path: 'components.invalidPatternError',
              name: 'Invalid pattern',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.invalidPatternError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            minLengthError: {
              path: 'components.minLengthError',
              name: 'Min length',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.minLengthError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            maxLengthError: {
              path: 'components.maxLengthError',
              name: 'Max length',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.maxLengthError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
          },
        },
        button: {
          path: 'components.button',
          name: 'Submit button',
          type: 'button',
          configEditor: {
            formProps: {
              excludedSettings: ['link'],
            },
          },
        },
        successMessage: {
          path: 'components.successMessage',
          name: 'Success message',
          type: 'text',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.successMessage',
              editorOptions: {
                isNestedArrayItem: true,
              },
            },
          ],
        },
        errorMessage: {
          path: 'components.errorMessage',
          name: 'Error message',
          type: 'text',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.errorMessage',
              editorOptions: {
                isNestedArrayItem: true,
              },
            },
          ],
        },
      },
      defaultConfig: {
        ...FORM_DEFAULTS,
        items: [
          formFieldSampleFactory({
            name: 'email',
            type: 'email',
            showLabel: false,
            placeholder: 'Enter your email',
            label: 'Email',
          }),
        ],
        flow: {
          data: {
            '--flow-default': 'row',
            '--flow-xs': 'column',
          },
        },
        components: {
          ...FORM_DEFAULTS.components,
          button: {
            ...FORM_DEFAULTS.components.button,
            children: textToLexical('Join waitlist'),
            font: {
              data: {
                default: {
                  ...FORM_DEFAULTS.components.button.font?.data?.default,
                  '--font-default-weight-default': 600,
                },
              },
            },
          },
          errorMessage: {
            ...FORM_DEFAULTS.components.errorMessage,
            children: textToLexical(
              'Something went wrong, and we couldn’t submit your email. Please try again.',
            ),
          },
        },
      },
    },
  }

export const featureWithFormBlockMeta: BlockMeta<FeatureWithFormBlock> = {}

export const featureWithFormDefaultConfig =
  blockDefaultConfigFactory<FeatureWithFormBlock>({
    componentMeta: featureWithFormComponentMeta,
    blockMeta: featureWithFormBlockMeta,
    version: 1,
  })
