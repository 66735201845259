import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'
import { textToLexical } from '@sceneio/wysiwyg-utils'

export const accordionItemComponentMeta: ComponentMeta<'accordionItem'> = {
  defaultConfig: {
    border: {
      data: {
        default: {
          '--border-default-color-default': '#000000',
          '--border-default-width-default': '1px',
          '--border-default-style-default': 'solid',
          '--border-default-top-style-default': 'solid',
          '--border-default-bottom-style-default': 'solid',
          '--border-default-left-style-default': 'solid',
          '--border-default-right-style-default': 'solid',
          '--border-default-radius-top-left-default': '0px',
          '--border-default-radius-top-right-default': '0px',
          '--border-default-radius-bottom-left-default': '0px',
          '--border-default-radius-bottom-right-default': '0px',
        },
      },
    },
    components: {
      headerContainer: {
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        gap: {
          data: {
            '--column-gap-default': '1rem',
          },
        },
        padding: {
          data: {
            '--padding-default-top': '1.25rem',
            '--padding-default-bottom': '1.25rem',
            '--padding-default-left': '1.5rem',
            '--padding-default-right': '1.5rem',
          },
        },
      },
      bodyContainer: {
        padding: {
          data: {
            '--padding-default-top': '0rem',
            '--padding-default-bottom': '1.5rem',
            '--padding-default-left': '1.5rem',
            '--padding-default-right': '1.5rem',
          },
        },
      },
      heading: {
        children: textToLexical('Question text goes here'),
        as: 'p',
        font: {
          data: {
            default: {
              '--font-default-weight-default': 'bold',
              '--font-default-size-default': '1.125rem',
              '--font-xs-size-default': '1rem',
            },
          },
        },
      },
      text: {
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.',
        ),
        as: 'p',
      },
      icon: {
        color: {
          data: {
            default: {
              '--color-default-default': '#000000',
            },
          },
        },
        backgroundColor: {
          data: {
            default: {
              '--background-color-default-default': 'transparent',
            },
            hover: {
              '--background-color-default-hover': 'transparent',
            },
            active: {
              '--background-color-default-active': 'transparent',
            },
          },
        },
        padding: {
          data: {
            '--padding-default-top': '2px',
            '--padding-default-right': '0px',
            '--padding-default-bottom': '2px',
            '--padding-default-left': '0px',
          },
        },
        font: {
          data: {
            default: {
              '--font-default-size-default': '25px',
            },
          },
        },
        border: {
          data: {
            hover: {
              '--border-default-color-hover': 'transparent',
            },
          },
        },
      },
    },
    swapIconSide: false,
    show: true,
  },
}

export const accordionItemDefaults =
  componentDefaultConfigFactory<'accordionItem'>({
    componentMeta: accordionItemComponentMeta,
  })
